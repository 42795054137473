import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import funcAPI from 'modules/product-result.v2/product-result.api'

import {
  StatPackagePreReservationDetailsResource,
} from 'be-structures/typescript-generator/assembly'

import {
  IConvertToPackageResult,
  IPageable,
  IConvertedQuery,
  IStatPackageSearchDigestResource
} from 'modules/common/common.types'

import {
  ComparablePackagesList,
  IComparablePackagesListClass,
  IPackageClass,
  IPackageCompareProductFilterClass,
  IPackageProduct,
  IPackageResponse,
  IPackagesListClass,
  PackageCompareProductFilter,
  PackagesList,
  TResultPendings,
  ResultPendingsNames,
  TResultPendingsNames,
  IDestinationsPackages,
  PackageProductDetails,
  Package,
  IAllDestinationsInfo,
  IAllDestinationInfo,
  IPackageProductClass,
  ICompanyClass,
  IPackagesWithDigest
} from 'modules/product-result.v2/data/product-result.types'
import {Store} from "vuex";

export const PRODUCT_RESULT_NAME = 'PRODUCT_RESULT_MODULE'

export const PRODUCT_RESULT = (http: any, store: Store<any>) => {
  const api = funcAPI(http)
  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

  @Module({ name: PRODUCT_RESULT_NAME, namespaced: true, store: store })
  class ProductResultModule extends VuexModule {
    private _quentityOfComparablePackages: number = 5
    private _comparablePackages: IComparablePackagesListClass = new ComparablePackagesList(null, this._quentityOfComparablePackages)
    private _favoritePackages: IPackagesListClass = new PackagesList(null)
    private _allDestinationsPackages: IDestinationsPackages = {}
    private _allDestinationsInfo: IAllDestinationsInfo = {}
    private _filter: IPackageCompareProductFilterClass = null
    private _defaultFilter: IPackageCompareProductFilterClass = null
    private _selectedPackage: IPackageClass = null
    private _pendings: TResultPendings = {
      getHotels: true,
      getAndSetHotels: true,
      getPackageProducts: true,
      getAndSetPackageProducts: true,
      getAndSetFirstPackageProduct: true,
      getProductsDetails: false
    }

    get pendings() {
      return this._pendings
    }

    get quentityOfComparablePackages() {
      return this._quentityOfComparablePackages
    }

    get comparablePackages() {
      return this._comparablePackages
    }

    get favoritePackages() {
      return this._favoritePackages
    }

    get allDestinationsPackages() {
      return this._allDestinationsPackages
    }

    get filter(): IPackageCompareProductFilterClass {
      return this._filter
    }

    get defaultFilter(): IPackageCompareProductFilterClass {
      return this._defaultFilter
    }

    get allDestinationsInfo() {
      return this._allDestinationsInfo
    }

    get selectedPackage() {
      return this._selectedPackage
    }


    @Mutation
    resetStore() {
      this._quentityOfComparablePackages = 5
      this._comparablePackages = new ComparablePackagesList(null, this._quentityOfComparablePackages)
      this._favoritePackages = new PackagesList(null)
      this._allDestinationsPackages = {}
      this._allDestinationsInfo = {}
      this._filter = null
      this._defaultFilter = null
      this._selectedPackage = null
      this._pendings = {
        getHotels: true,
        getAndSetHotels: true,
        getPackageProducts: true,
        getAndSetPackageProducts: true,
        getAndSetFirstPackageProduct: true,
        getProductsDetails: false
      }
    }
    
    @Mutation
    setPending({ pendingName, value }: { pendingName: keyof typeof ResultPendingsNames, value: boolean }) {
      this._pendings[pendingName] = value
    }

    @Mutation
    setAllDestinationsPackages(allDestinationsPackages: IDestinationsPackages) {
      this._allDestinationsPackages = allDestinationsPackages
    }

    @Mutation
    setDestinationPackage({ countryCode, packagesResult }: { countryCode: string, packagesResult: IPackageResponse }) {
      this._allDestinationsPackages = {
        ...this._allDestinationsPackages,
        [countryCode]: new PackagesList(packagesResult.content, packagesResult.page.query.digest.price.min)
      }
    }

    @Mutation
    setNightsIntervalForPackage({ countryCode, hotelId, nightsInterval }: { countryCode: string, hotelId: string, nightsInterval: IPackageClass['nightsInterval'] }) {
      const destinationPackage = this._allDestinationsPackages[countryCode].getPackage({ hotelId })
      destinationPackage.nightsInterval = nightsInterval
    }

    @Mutation
    setPackageProducts({ countryCode, hotelId, products }: { countryCode: string, hotelId: string, products: IPackageProduct[] }) {
      const destinationPackages = this._allDestinationsPackages[countryCode].getPackage({ hotelId })
      destinationPackages.setProducts(products)
    }

    @Mutation
    setFilter(filter: IPackageCompareProductFilterClass) {
      this._filter = filter
    }

    @Mutation
    setDefaultFilter(defaultFilter: IPackageCompareProductFilterClass) {
      this._defaultFilter = defaultFilter
    }

    @Mutation
    resetFilter() {
      this._filter = new PackageCompareProductFilter({
        filterData: this._defaultFilter.content,
        vacationTypes: this._filter.vacationTypes
      })
    }

    @Mutation
    setFavoritePackages(favoritePackages: IPackagesListClass) {
      this._favoritePackages = favoritePackages
    }

    @Mutation
    addPackageToFavorite({ currentPackage }: { currentPackage: IPackageClass }) {
      this._favoritePackages.addPackage({ currentPackage })
    }

    @Mutation
    removePackageFromFavorite({ currentPackage }: { currentPackage: IPackageClass }) {
      this._favoritePackages.removePackage({ currentPackage })
    }

    @Mutation
    setComparablePackages(comparablePackages: IComparablePackagesListClass) {
      this._comparablePackages = comparablePackages
    }

    @Mutation
    addPackageToCompare({ currentPackage, index }: { currentPackage: IPackageClass, index?: number }) {
      this._comparablePackages.addPackage({ currentPackage, index })
    }

    @Mutation
    removePackageFromCompare({ currentPackage }: { currentPackage: IPackageClass }) {
      this._comparablePackages.removePackage({ currentPackage })
    }

    @Mutation
    initializeAllDestinationInfo() {
      this._allDestinationsInfo = {}
    }

    @Mutation
    setAllDestinationsInfo({ countryCode, info }: { countryCode: string, info: IAllDestinationInfo }) {
      this._allDestinationsInfo = {
        ...this._allDestinationsInfo,
        [countryCode]: info
      }
    }

    @Mutation
    initializeAllDestinationsPackages() {
      this._allDestinationsPackages = {}
    }

    @Mutation
    removeDestinationPackage({ countryCode }: { countryCode: string }) {
      this._allDestinationsPackages[countryCode] = null
    }

    // TODO: перенести во view-store
    @Mutation
    setSelectedPackage(selectedPackage: IPackageClass) {
      this._selectedPackage = selectedPackage
    }


    @Action
    initializeResultFilter({ digest }: { digest: IStatPackageSearchDigestResource }) {
      // TODO: resolve types in filter class
      // @ts-ignore
      const defaultFilter = digest ? new PackageCompareProductFilter({ filterData: digest, isDefaultFilter: true }) : null
      // @ts-ignore
      const filter = digest ? new PackageCompareProductFilter({ filterData: digest }) : null
      
      this.context.commit('setFilter', filter)
      this.context.commit('setDefaultFilter', defaultFilter)
    }

    @Action
    async getDestinationHotels({
      query,
      filter,
      pageable,
    }: {
      query: IConvertedQuery
      filter?: IConvertToPackageResult
      pageable?: IPageable
    }): Promise<IPackageResponse> {
      try {
        this.context.commit('setPending', { pendingName: 'getHotels' as TResultPendingsNames, value: true })

        return await api.getActiveHotels({
          filter,
          pageable,
          query,
        })
      } finally {
        this.context.commit('setPending', { pendingName: 'getHotels' as TResultPendingsNames, value: false })
      }
    }

    @Action
    async getAndSetDestinationHotels({
      query,
      filter,
      pageable,
    }: {
      query: IConvertedQuery
      filter?: IConvertToPackageResult
      pageable?: IPageable
    }): Promise<IPackagesWithDigest> {
      try {
        this.context.commit('setPending', { pendingName: 'getAndSetHotels' as TResultPendingsNames, value: true })

        const packagesResult = await this.getDestinationHotels({
          query,
          filter,
          pageable,
        })
    
        this.context.commit('setDestinationPackage', { countryCode: query.countryCode, packagesResult })

        return { packages: packagesResult.content, digest: packagesResult.page.effectiveDigest }
      } finally {
        this.context.commit('setPending', { pendingName: 'getAndSetHotels' as TResultPendingsNames, value: false })
      }
    }

    @Action
    async getPackageProducts({
      hotelId,
      filter,
      query
    }: {
      hotelId: string,
      filter?: IConvertToPackageResult,
      query?: IConvertedQuery
    }) {
      try {
        this.context.commit('setPending', { pendingName: 'getPackageProducts' as TResultPendingsNames, value: true })

        return await api.getProductsByHotel({
          filter,
          hotelId,
          query
        })
      } finally {
        this.context.commit('setPending', { pendingName: 'getPackageProducts' as TResultPendingsNames, value: false })
      }
    }

    @Action
    async getAndSetFirstPackageProduct({
      countryCode,
      hotelId,
      filter,
      query
    }: {
      countryCode: string,
      hotelId: string,
      filter?: IConvertToPackageResult,
      query?: IConvertedQuery
    }) {
      try {
        this.context.commit('setPending', { pendingName: 'getAndSetFirstPackageProduct' as TResultPendingsNames, value: true })

        const data = await api.getProductsByHotel({
          filter,
          hotelId,
          query,
          pageable: { pageSize: 1 }
        })

        this.context.commit('setNightsIntervalForPackage', {
          countryCode,
          hotelId,
          nightsInterval: data.page.effectiveDigest.nights
        })
    
        this.context.commit('setPackageProducts', {
          countryCode,
          hotelId,
          products: data.content
        })
      } finally {
        this.context.commit('setPending', { pendingName: 'getAndSetFirstPackageProduct' as TResultPendingsNames, value: false })
      }
    }

    @Action
    async getAndSetPackageProducts({
      countryCode,
      hotelId,
      filter,
      query
    }: {
      countryCode: string,
      hotelId: string,
      filter?: IConvertToPackageResult,
      query?: IConvertedQuery
    }) {
      try {
        this.context.commit('setPending', { pendingName: 'getAndSetPackageProducts' as TResultPendingsNames, value: true })

        const data = await api.getProductsByHotel({
          filter,
          hotelId,
          query
        })

        this.context.commit('setNightsIntervalForPackage', {
          countryCode,
          hotelId,
          nightsInterval: data.page.effectiveDigest.nights
        })
    
        this.context.commit('setPackageProducts', {
          countryCode,
          hotelId,
          products: data.content
        })
      } finally {
        this.context.commit('setPending', { pendingName: 'getAndSetPackageProducts' as TResultPendingsNames, value: false })
      }
    }

    @Action
  async getDetailsForPackageProduct({
    productId
  }:{
    productId: string
  }): Promise<StatPackagePreReservationDetailsResource> {
    return new Promise(async (resolve, reject) => {
      const details = await api.getProductDetails({ productId })

      if (!details) {
        resolve(null)
      } else if (details.status === 'AWAIT') {
        await delay(800)
        resolve(this.getDetailsForPackageProduct({ productId }))
      } else if (details.status === 'ERROR') {
        resolve(null)
      } else {
        resolve(details)
        }
      })
    }

    @Action
    async getDetailsPriceForPackageProduct({ entityId, productId, complectIds }: { entityId: string, productId: string, complectIds: string[] }) {
      return api.getPaxComplectAgencyPrices({
        entityId,
        complectIds,
        productId
      })
    }

  @Action
  async getAndSetDetailsForPackageProduct({
    product,
    agency
  }:{
    product: IPackageProductClass
    agency: ICompanyClass
  }) {
    try {
      // TODO: must be done with mutation
      this.context.commit('setPending', { pendingName: 'getProductsDetails' as TResultPendingsNames, value: true })
      
      const productId = product.productId
      const entityId = agency.entityId
      const result = await this.getDetailsForPackageProduct({ productId })

      if (result) {
        const details = result.paxComplects
        const detailsPrice = await this.getDetailsPriceForPackageProduct({
          entityId,
          productId,
          complectIds: details.complects.map(c => c.id)
        })
  
        product.details = new PackageProductDetails({
          ...details,
          complects: details.complects.map(detail => {
            return {
              ...detail,
              price: detailsPrice.find(dp => dp.complectId === detail.id).price
            }
          }),
        })
      }
    } finally {
      this.context.commit('setPending', { pendingName: 'getProductsDetails' as TResultPendingsNames, value: false })
    }
  }

    // TODO: DEPRECATED ACTION
    @Action
    async initPackage({ packageContent }: { packageContent: any }) {
      const packageClass = new Package({
        ...packageContent.content,
        products: packageContent.products
      })

      if (packageContent.details) {
        packageClass.products.productsIds.map(id => {
          const product = packageClass.products.getProduct(id)
          product.details = new PackageProductDetails(
            packageContent.details.find((det: any) => det[0] === id)[1]
          )
        })
      }
      
      return packageClass
    }
  }

  return ProductResultModule
}
