import Vue from 'vue'
import Vue2TouchEvents from 'vue2-touch-events'

import index from 'applications/mobile/index.vue'
import i18n from 'applications/i18n'
import vuetify from 'applications/desktop/vuetify'
import router from 'applications/mobile/router'
import InputFacade from 'vue-input-facade'
import maskOptions from 'common/mask/facade'
import initializeHTTP from 'common/http/http'
import initializeStore from 'applications/common/store/store'
import persistModules from 'applications/mobile/store/persistenceConfig'
import getStoreModules from 'applications/common/store/storeModules'
import {startPwa} from "applications/mobile/pwa";
import { TLocaleType } from 'modules/i18n/data/i18n.types'

Vue.use(InputFacade, maskOptions)

const store = initializeStore(persistModules)
const http = initializeHTTP(store)
const storeModules = getStoreModules(http, store)

if (typeof localStorage.APP_VERSION === 'undefined' || localStorage.APP_VERSION === null) {
  localStorage.setItem('APP_VERSION', process.env.APP_VERSION);
}

if (localStorage.APP_VERSION !== process.env.APP_VERSION) {
  const appLocale = localStorage.getItem('app_locale') || process.env.APP_LOCALE
  const destinationPackage = localStorage.getItem('destinationPackage')
  localStorage.clear();
  localStorage.setItem('app_locale', appLocale) // to save the locale after re-loading
  localStorage.setItem('destinationPackage', destinationPackage) // to open Deal Details after reloading
}
if (process.env.APP_LOCALE) {
  storeModules.i18n.changeLocale({ locale: localStorage.getItem('app_locale') as TLocaleType, setDefault: true })
} else {
  storeModules.i18n.setDefaultLocale()
}
startPwa();

new Vue({
  el: '#app',
  i18n,
  render: h => h(index),
  router,
  store,
  vuetify
})

Vue.use(Vue2TouchEvents, {
  namespace: 'mobile-touch'
})

export {
  http,
  store
}
