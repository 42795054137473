import { Store } from 'vuex'

import registerStoreModule from 'modules/common/common-methods/register-store-module'
import {
  PRODUCT_SEARCH_NAME,
  PRODUCT_SEARCH
} from './product-search.store'

export default (http, store: Store<any>): void => {
  registerStoreModule({
    name: PRODUCT_SEARCH_NAME,
    storeModule: PRODUCT_SEARCH,
    http,
    store
  })
}