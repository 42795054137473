import { Store } from 'vuex'

import registerStoreModule from 'modules/common/common-methods/register-store-module'
import { PRODUCT_RESULT_VIEW_NAME, PRODUCT_RESULT_VIEW } from './store'

export default (http, store: Store<any>): void => {
  registerStoreModule({
    name: PRODUCT_RESULT_VIEW_NAME,
    storeModule: PRODUCT_RESULT_VIEW,
    http,
    store
  })
}