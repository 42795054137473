import axios, {
  AxiosError,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'
import qs from 'qs'
import { Store } from 'vuex'

const queryString = window.location.hash.split('?')[1]
const params: any = new Proxy(new URLSearchParams(queryString), {
  get: (searchParams, prop) => typeof prop === 'string' ? searchParams.get(prop) : null,
});

export default (store: Store<any>) => {
  const instance: AxiosInstance = axios.create({
    baseURL: process.env.API_BASE_URL,
    headers: {
      Accept: 'application/json, text/plain, */*',
      get: {
        'Content-Type': 'application/json'
      },
      post: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    },
    timeout: 300000,
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  })
  
  instance.interceptors.request.use(
    (request) => requestHandler(request)
  )
  
  instance.interceptors.response.use(
    (response: AxiosResponse) => responseHandler(response),
    (error: AxiosError) => errorHandler(error)
  )
  
  const requestHandler = (request: AxiosRequestConfig) => {
    request.headers = {
      ...request.headers,
      'Accept-Language': store.getters['i18n_MODULE/currentFormattedLocale']
    }
  
    return request
  }
  
  const responseHandler = (response: AxiosResponse) => {
    return response.data
  }
  
  const errorHandler = (error: AxiosError) => {
    return Promise.reject(error.response)
  }
  
  return class Http {
    static post(
      url: string,
      data: any,
      config?: AxiosRequestConfig
    ): AxiosPromise {
      return instance.post(url, {
        referralCode: params.ref,
        ...data
      }, config)    }
  
    static get(url: string, data: any, headers?: any): AxiosPromise {
      return instance.get(url, { params: {
          referralCode: params.ref,
          ...data
        }, headers })    }
  
    static put(
      url: string,
      data: any,
      config?: AxiosRequestConfig
    ): AxiosPromise {
      return instance.put(url, {
        referralCode: params.ref,
        ...data
      }, config)    }
  }
  
}