import cloneDeep from 'lodash/cloneDeep'

import {
  IPaxComplectWithPriceResource,
  IPaxComplect,
  PAX_COMPLECT_BLANK
} from 'modules/product-result.v2/data/product-result.types'

import { PackageProductPrice } from 'modules/product-result.v2/data/package-result/package-result.product-price/package-result.product-price.class'

export const convertIPaxComplectWithPriceResourceToIPaxComplect = (({ paxComplect }: { paxComplect?: IPaxComplectWithPriceResource }): IPaxComplect => {
  const convertedPaxPrice = cloneDeep(PAX_COMPLECT_BLANK)

  if (paxComplect) {
    for (const item of Object.keys(PAX_COMPLECT_BLANK)) {
      convertedPaxPrice[item] = paxComplect[item]
    }
    convertedPaxPrice.price = new PackageProductPrice(paxComplect.price)
  }
  
  return convertedPaxPrice
})