











































































































































export default {
  name: "cert"
};
