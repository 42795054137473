import PackagesList from 'modules/product-result.v2/data/package-result/package-result.packages-list/package-result.packages-list.class'
import {
  HotelFacilityGroupsTypes,
  IComparablePackagesListClass,
  IPackageClass,
  IPackageHotelFacility,
  IPackage
} from 'modules/product-result.v2/data/product-result.types'

import {
  setClassName
} from 'modules/common/common-methods/common-methods'

export default class ComparablePackagesList extends PackagesList implements IComparablePackagesListClass {

  constructor(packageResponse: IPackage[], private _quantityOfPackages: number) {
    super(packageResponse)
    setClassName(ComparablePackagesList.name, this)
  }

  addPackage({ currentPackage, index }: { currentPackage: IPackageClass, index?: number }): void {
    if (Number.isInteger(index)) {
      if (index <= this._quantityOfPackages) {
        // TODO: fix no-reactive method
        super.content[index] = null
        super.content.splice(index, 1, currentPackage)
      }
    } else if (super.content.length === 0) {
      super.content = Array(currentPackage)
    } else if (super.content.length <= this._quantityOfPackages) {
      const emptyIndex = Array(this._quantityOfPackages).findIndex((el, i) => !super.content[i])

      if (emptyIndex !== -1) {
        super.content.splice(emptyIndex, 1, currentPackage)
      }
    }
  }

  removePackage({ currentPackage }: { currentPackage: IPackageClass }): void {
    const packageIndex = super.getPackageIndex({ hotelId: currentPackage.hotel.hotelId })

    if (packageIndex !== -1) {
      super.content.splice(packageIndex, 1)
    }
  }

  concatFacilitiesForAllPackages(): IPackageHotelFacility[] {
    let facilities: IPackageHotelFacility[] = []
    
    super.content.map(currentPackage => {
      facilities = [
        ...facilities,
        ...(currentPackage?.hotel.facilities || [])
      ]
    })

    return facilities
  }

  getUniqueFacilitiesForAllPackages(): IPackageHotelFacility[] {
    const facilities = this.concatFacilitiesForAllPackages()

    return [...new Set(facilities.map(facility => JSON.stringify(facility)))].map(facility => JSON.parse(facility))
  }

  classifyFacilitesIntoGroupsForAllPackages({ groupName }: { groupName: keyof typeof HotelFacilityGroupsTypes }): IPackageHotelFacility[] {
    return (
      this.getUniqueFacilitiesForAllPackages().filter(
        facility => facility.groupName === groupName
      ) || []
    )
  }
}
