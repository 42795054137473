import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'

import lazyLoadRoute from 'common/load-async-components'

const PrepareSearchData = () => import('applications/mobile/components/prepare-search-data/prepare-search-data.template.vue')
const CompareProducts = () => import('applications/mobile/components/compare-products/compare-products.template.vue')
const FavoriteProducts = () => import('applications/mobile/components/favorite-products/search-results.favorite.template.vue')

Vue.use(Router)

const routes: any[] = [
  {
    component: PrepareSearchData,
    name: 'package-compare.prepare-search-data',
    path: '/'
  },
  {
    component: () => import('applications/mobile/components/search-results/search-results.template.vue'),
    name: 'package-compare.search-results',
    path: '/hotels',
    children: [
      {
        component: CompareProducts,
        name: 'package-compare.compare-products',
        path: 'comparison'
      },
      {
        component: FavoriteProducts,
        name: 'package-compare.favorite-search-results',
        path: 'favorite'
      }
    ]
  },
  {
    component: () => import('applications/mobile/components/order/order.template.vue'),
    name: 'order',
    path: '/order'
  }
]

export default new Router({ routes })
