import {
  IConvertToPackageResult,
  IConvertedQuery,
  IContentResponse,
  IVacationTerm
} from 'modules/common/common.types'

import {
  IATACountryResource,
  IATACityResource
} from 'be-structures/typescript-generator/assembly'

export default (http: any) => {
  const getActiveCountriesForCalendar = (): IATACountryResource[] => {
    return http.get('/customer/search/statpackage/calendar/countries', {})
  }


  const getReferralRule = ({
  referralCode
  }: {
  referralCode: string
  }) => {
    return http.get(
        `/customer/compare/statpackage/calendar/referralRule/${referralCode}`,
        {}
    )
  }

  const getActiveCountries = ({
    query,
    filter
  }: {
    query: IVacationTerm,
    filter: IConvertToPackageResult
  }): Promise<IContentResponse<IATACountryResource[]>> => {
    return http.put('customer/compare/statpackage/calendar/vacation/countries/page', {
      pageable: {
        offset: 0,
        pageNumber: 0,
        pageSize: 50,
        paged: true,
        sort: [
          {
            NAME: 'ASC'
          }
        ],
        unpaged: false
      },
      productFilter: filter || null,
      vacationTerm: query
    })
  }

  const getActiveCities = ({
    query,
    filter
  }: {
    query: IConvertedQuery,
    filter: IConvertToPackageResult
  }): Promise<IContentResponse<IATACityResource[]>> => {
    return http.put(
      '/customer/compare/statpackage/calendar/vacation/cities/page',
      {
        pageable: {
          offset: 0,
          pageNumber: 0,
          pageSize: 50,
          paged: true,
          sort: [
            {
              NAME: 'ASC'
            }
          ],
          unpaged: false
        },
        productFilter: filter,
        ...query
      }
    )
  }

  const getDepartureDatesByCountry = (({ countryCode }: { countryCode: string }) => {
    return http.get(`customer/compare/statpackage/calendar/countries/${countryCode}/departures`)
  })

  const getReturnDatesByCountry = (({ cityCode, departureDate }: { cityCode: string, departureDate: string }) => {
    return http.get(`customer/compare/statpackage/calendar/cities/${cityCode}/departures/${departureDate}/returns`)
  })

  const getBestDeals = ({ query }: { query: IConvertedQuery }) => {
    const data = {
      vacationTerm: query.vacationTerm
    }
    return http.put(`customer/compare/statpackage/calendar/vacation/bests`, {
      pageable: {
        offset: 0,
        pageNumber: 0,
        pageSize: 14,
        paged: true,
        sort: [
          {
            NAME: 'ASC'
          }
        ],
        unpaged: false
      },
      ...data
    })
  }

  return {
    getActiveCities,
    getActiveCountries,
    getDepartureDatesByCountry,
    getReturnDatesByCountry,
    getActiveCountriesForCalendar,
    getBestDeals,
    getReferralRule
  }
}
