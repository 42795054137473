

















import Component from 'vue-class-component'

import TlIcons from "ui-modules/icons/icon";
import {Prop, Vue} from "vue-property-decorator";
import {Accessibility} from "applications/desktop/accessibility/accessibility";
@Component({
  name: "AccessBtn",
  components: {
    TlIcons,
  }
})
export default class AccessBtn extends Vue {
  @Prop({type: Accessibility, required: true}) startAccessibility: Accessibility;

  toggleAccessibility(status: boolean) {
    this.startAccessibility.toggleAccessibility(status);
  }
  get isAccessibility(): boolean {
    return this.startAccessibility.isAccessibility;
  }
};
