import {
  IConvertToPackageResult,
  IConvertedQuery,
  IPageable,
  IContentResponse,
  IPackageProduct,
  ICompanyClass
} from 'modules/product-result.v2/data/product-result.types'

import {
  PaxComplectAgencyPriceResource,
  StatPackagePreReservationDetailsResource
} from 'be-structures/typescript-generator/assembly'

export default (http: any) => {
  const getProductDetails = ({ productId, filter }: { productId: string, filter?: any }): Promise<StatPackagePreReservationDetailsResource> => {
    return http.get(
      `/customer/search/statpackage/products/${productId}/details`,
      {
        ...filter
      }
    )
  }
  
  const getPaxComplectAgencyPrices = ({
    productId,
    entityId,
    complectIds,
    filter
  }: {
    productId: string
    entityId: string
    complectIds: string[]
    filter?: any
  }): Promise<PaxComplectAgencyPriceResource[]> => {
    return http.get(
      `/customer/search/statpackage/products/${productId}/details/prices?agencyId=${entityId}&${encodeURIComponent(
        'complectIds[]'
      )}=${complectIds}`,
      {}
    )
  }

  const getActiveHotels = ({
    filter,
    pageable,
    query
  }: {
    filter?: IConvertToPackageResult
    pageable?: IPageable
    query: IConvertedQuery
  }) => {
    return http.put('/customer/compare/statpackage/calendar/vacation/hotels', {
      pageable: {
        offset: 0,
        pageNumber: 0,
        pageSize: 100,
        paged: true,
        sort: [
          {
            PRICE: 'ASC'
          }
        ],
        unpaged: false,
        ...pageable
      },
      productFilter: filter,
      ...query
    })
  }
  
  const getProductsByHotel = ({
    filter,
    pageable,
    hotelId,
    query
  }: {
    filter?: IConvertToPackageResult
    pageable?: IPageable
    hotelId: string
    query: IConvertedQuery
  }): Promise<IContentResponse<IPackageProduct[]>> => {
    return http.put('customer/compare/statpackage/calendar/vacation/products/page', {
      pageable: {
        offset: 0,
        pageNumber: 0,
        pageSize: 100,
        paged: true,
        sort: [
          {
            PRICE: 'ASC'
          }
        ],
        unpaged: false,
        ...pageable
      },
      hotelId,
      productFilter: filter,
      vacationTerm: query['vacationTerm']
    })
  }

  return {
    getPaxComplectAgencyPrices,
    getProductDetails,
    getActiveHotels,
    getProductsByHotel
  }
}
