import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import {
  TLocaleType,
  Locale,
  LocaleTypes,
  ILocaleClass
} from 'modules/i18n/data/i18n.types'

export const i18n_NAME = 'i18n_MODULE'

export const i18n = () => {
  @Module({ name: i18n_NAME, namespaced: true })
  class i18nModule extends VuexModule {
    private _direction = 'ltr'
    private _localeClass: ILocaleClass =  new Locale(LocaleTypes.en)
    private _locales = {
      [LocaleTypes.en]: 'en-US',
      [LocaleTypes.he]: 'he-HE',
      [LocaleTypes.ru]: 'ru-RU'
    }


    get direction() {
      return this._direction
    }

    get localeClass() {
      return this._localeClass
    }

    get locales() {
      return this._locales
    }

    get currentLocale() {
      return this._localeClass.locale
    }

    get usedLocale() {
      return this._localeClass.usedLocale
    }

    get currentFormattedLocale() {
      return this._localeClass.getFormattedLocale()
    }


    @Mutation
    setLocaleClass(locale: ILocaleClass) {
      this._localeClass = locale
    }

    @Mutation
    setLocale({ locale }: { locale: TLocaleType }) {
      this._localeClass.locale = locale
    }

    @Mutation
    setDirection({ direction }: { direction: string }) {
      this._direction = direction
    }

    @Action
    setDefaultLocale() {
      this.changeLocale({
        locale: this.usedLocale,
        setDefault: true
      })
    }


    @Action
    changeLocale({ locale, setDefault }: { locale: TLocaleType; setDefault?: boolean }) {
      if (this.currentLocale !== locale || setDefault) {
        this._localeClass.changeLocale(locale)
        this._localeClass.applyLocale(locale)
        window.localStorage.setItem('app_locale', locale)

        const direction = this._localeClass.getLocaleDirection(locale)
        document.body.setAttribute('dir', direction)

        this.context.commit('setLocale', { locale })
        this.context.commit('setDirection', { direction })
      }
    }
  }

  return i18nModule
}
