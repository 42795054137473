import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import getHTTP from 'common/http/http'
import getStoreModules from 'applications/common/store/storeModules'

import {
  AppMode
} from 'applications/common/types/common.types'

@Component
export default class RouterMixin extends Vue {
  get storeModules() {
    return getStoreModules(getHTTP(this.$store), this.$store)
  }



  get isHomePage() {
    return this.$route.name === 'package-compare.prepare-search-data'
  }

  get isSearchResultsPage() {
    return this.$route.name === 'package-compare.search-results'
  }

  get isFavoritePackagesPage() {
    return this.$route.name === 'package-compare.favorite-search-result'
  }

  get isComparablePackagesPage() {
    return this.$route.name === 'package-compare.compare-products'
  }

  get isOrderPage() {
    return this.$route.name === 'order'
  }

  // goToHomePage() {
  //   this.$router.push({ name: 'package-compare.prepare-search-data' })
  // }

  // goToSearchResultsPage() {
  //   this.$router.push({ name: 'package-compare.search-results' })
  // }

  // goToComparablePackagesPage() {
  //   this.$router.push({ name: 'package-compare.compare-products' })
  // }

  goToResultPage(appMode: AppMode = AppMode.NORMAL): void {
    // this.storeModules.PACKAGE_COMPARE_DESKTOP.setAppMode({ appMode })
    this.$router.push({ name: 'package-compare.search-results' })
  }

  goToFavoritePackagesPage(appMode: AppMode = AppMode.NORMAL): void {
    // this.storeModules.PACKAGE_COMPARE_DESKTOP.setAppMode({ appMode })
    this.$router.push({ name: 'package-compare.favorite-search-results' })
  }

  goToComparablePackagesPage(appMode: AppMode = AppMode.NORMAL): void {
    // this.storeModules.PACKAGE_COMPARE_DESKTOP.setAppMode({ appMode })
    this.$router.push({ name: 'package-compare.compare-products' })
  }

  goToHomePage(appMode: AppMode = AppMode.NORMAL): void {
    // this.storeModules.PACKAGE_COMPARE_DESKTOP.setAppMode({ appMode })
    this.$router.push({ name: 'package-compare.prepare-search-data' })
  }

  goToSearchResultsPage(appMode: AppMode = AppMode.NORMAL): void {
    // this.storeModules.PACKAGE_COMPARE_DESKTOP.setAppMode({ appMode })
    this.$router.push({ name: 'package-compare.search-results' })
  }
}
