
import PackageHotel from 'modules/product-result.v2/data/package-result/package-result.hotel/package-result.hotel.class'

import {
  IMinMax
} from 'modules/common/common.types'

import {
  IMedia,
  IPartOfList,
  ICity
} from 'modules/product-result.v2/data/product-result.types'

enum HotelFacilityGroupsTypes {
  ACTIVITIES = 'ACTIVITIES',
  COMFORT_ROOM = 'COMFORT_ROOM',
  COMFORT_HOTEL = 'COMFORT_HOTEL',
  SHUTTLE = 'SHUTTLE',
  OTHERS = 'OTHERS',
  HEALTHBEATY = 'HEALTHBEATY',
  FORCHILDREN = 'FORCHILDREN',
  BEACH = 'BEACH'
}

enum HotelFacilitiesTypes {
  'Hotel' = 'Hotel',
  'Room' = 'Room'
}

enum HotelRemarksTypes {
  'location' = 'location',
  'room_count' = 'room_count',
  'room_desc' = 'room_desc',
  'restaurant' = 'restaurant'
}

export type THotelRemarksTypes = keyof typeof HotelRemarksTypes
export type THotelFacilitiesTypes = keyof typeof HotelFacilitiesTypes

enum HotelFacilitiesCodes {
  'bar' = 'bar',
  'golf' = 'golf',
  'WIFI' = 'WIFI',
  'cinema' = 'cinema',
  'theater' = 'theater',
  'swimmingPool' = 'swimmingPool',
  'casino' = 'casino',
  'coffeeShop' = 'coffeeShop',
  'restaurants' = 'restaurants',
  'stores' = 'stores',
  'disco' = 'disco',
  'biliards' = 'biliards',
  'squash' = 'squash',
  'tabletennis' = 'tabletennis',
  'waterSports' = 'waterSports',
  'tennis' = 'tennis',
  'sightseeing' = 'sightseeing',
  'poolbar' = 'poolbar',
  'snowSkiing' = 'snowSkiing',
  'executiveFlr' = 'executiveFlr',
  'wake_up' = 'wake_up',
  'kitchen' = 'kitchen',
  'nonsmokingRms' = 'nonsmokingRms',
  'treatments' = 'treatments',
  'parking' = 'parking',
  'exchangeFac' = 'exchangeFac',
  'ironing' = 'ironing',
  'conventionCtr' = 'conventionCtr',
  'handicap' = 'handicap',
  'iceMachines' = 'iceMachines',
  'carRental' = 'carRental',
  'womensGstRms' = 'womensGstRms',
  'elevator' = 'elevator',
  'lounge' = 'lounge',
  'valetParking' = 'valetParking',
  'pcHookupInrm' = 'pcHookupInrm',
  'laundrySvc' = 'laundrySvc',
  'meetingRooms' = 'meetingRooms',
  'television' = 'television',
  'hairDryer' = 'hairDryer',
  'minibar' = 'minibar',
  'roomService' = 'roomService',
  'movieChannels' = 'movieChannels',
  'phoneDirDial' = 'phoneDirDial',
  'coffee_tea' = 'coffee_tea',
  'radio' = 'radio',
  'balcony' = 'balcony',
  'aircondition' = 'aircondition',
  'faxFacInroom' = 'faxFacInroom',
  'safeDepBox' = 'safeDepBox',
  'rommIron' = 'rommIron',
  'shuttle' = 'shuttle',
  'businessCtr' = 'businessCtr',
  'giftShop' = 'giftShop',
  'pharmacy' = 'pharmacy',
  'hairdresser' = 'hairdresser',
  'spa' = 'spa',
  'jacuzzi' = 'jacuzzi',
  'swimmingPoolindoor' = 'swimmingPoolindoor',
  'sauna' = 'sauna',
  'solarium' = 'solarium',
  'beautyParlour' = 'beautyParlour',
  'massage' = 'massage',
  'hamam' = 'hamam',
  'healthClub' = 'healthClub',
  'childrenClub' = 'childrenClub',
  'childrenActivities' = 'childrenActivities',
  'babySitting' = 'babySitting',
  'beach' = 'beach'
}

const HOTEL_FACILITIES: { [key in HotelFacilitiesCodes]: IFacilityGroup } = {
  cinema: {
    code: 'cinema',
    groupName: 'ACTIVITIES',
    priority: 6
  },
  theater: {
    code: 'theater',
    groupName: 'ACTIVITIES',
    priority: 15
  },
  swimmingPool: {
    code: 'swimmingPool',
    groupName: 'ACTIVITIES',
    priority: 0
  },
  casino: {
    code: 'casino',
    groupName: 'ACTIVITIES',
    priority: 5
  },
  coffeeShop: {
    code: 'coffeeShop',
    groupName: 'ACTIVITIES',
    priority: 16
  },
  restaurants: {
    code: 'restaurants',
    groupName: 'ACTIVITIES',
    priority: 1
  },
  bar: { groupName: 'ACTIVITIES', code: 'bar', priority: 4 },
  stores: {
    code: 'stores',
    groupName: 'ACTIVITIES',
    priority: 14
  },
  disco: {
    code: 'disco',
    groupName: 'ACTIVITIES',
    priority: 2
  },
  biliards: {
    code: 'biliards',
    groupName: 'ACTIVITIES',
    priority: 13
  },
  squash: {
    code: 'squash',
    groupName: 'ACTIVITIES',
    priority: 12
  },
  tabletennis: {
    code: 'tabletennis',
    groupName: 'ACTIVITIES',
    priority: 11
  },
  waterSports: {
    code: 'waterSports',
    groupName: 'ACTIVITIES',
    priority: 10
  },
  tennis: {
    code: 'tennis',
    groupName: 'ACTIVITIES',
    priority: 3
  },
  golf: { groupName: 'ACTIVITIES', code: 'golf', priority: 7 },
  sightseeing: {
    code: 'sightseeing',
    groupName: 'ACTIVITIES',
    priority: 9
  },
  poolbar: {
    code: 'poolbar',
    groupName: 'ACTIVITIES',
    priority: 8
  },
  snowSkiing: {
    code: 'snowSkiing',
    groupName: 'COMFORT_HOTEL',
    priority: 5
  },
  executiveFlr: {
    code: 'executiveFlr',
    groupName: 'COMFORT_HOTEL',
    priority: 16
  },
  wake_up: {
    code: 'wake_up',
    groupName: 'COMFORT_HOTEL',
    priority: 17
  },
  kitchen: {
    code: 'kitchen',
    groupName: 'COMFORT_HOTEL',
    priority: 7
  },
  nonsmokingRms: {
    code: 'nonsmokingRms',
    groupName: 'COMFORT_HOTEL',
    priority: 19
  },
  treatments: {
    code: 'treatments',
    groupName: 'COMFORT_HOTEL',
    priority: 8
  },
  parking: {
    code: 'parking',
    groupName: 'COMFORT_HOTEL',
    priority: 4
  },
  exchangeFac: {
    code: 'exchangeFac',
    groupName: 'COMFORT_HOTEL',
    priority: 15
  },
  ironing: {
    code: 'ironing',
    groupName: 'COMFORT_HOTEL',
    priority: 6
  },
  conventionCtr: {
    code: 'conventionCtr',
    groupName: 'COMFORT_HOTEL',
    priority: 13
  },
  handicap: {
    code: 'handicap',
    groupName: 'COMFORT_HOTEL',
    priority: 14
  },
  iceMachines: {
    code: 'iceMachines',
    groupName: 'COMFORT_HOTEL',
    priority: 20
  },
  carRental: {
    code: 'carRental',
    groupName: 'COMFORT_HOTEL',
    priority: 9
  },
  womensGstRms: {
    code: 'womensGstRms',
    groupName: 'COMFORT_HOTEL',
    priority: 12
  },
  elevator: {
    code: 'elevator',
    groupName: 'COMFORT_HOTEL',
    priority: 3
  },
  lounge: {
    code: 'lounge',
    groupName: 'COMFORT_HOTEL',
    priority: 10
  },
  valetParking: {
    code: 'valetParking',
    groupName: 'COMFORT_HOTEL',
    priority: 11
  },
  WIFI: {
    code: 'WIFI',
    groupName: 'COMFORT_ROOM',
    priority: 0
  },
  pcHookupInrm: {
    code: 'pcHookupInrm',
    groupName: 'COMFORT_ROOM',
    priority: 1
  },
  laundrySvc: {
    code: 'laundrySvc',
    groupName: 'COMFORT_ROOM',
    priority: 2
  },
  meetingRooms: {
    code: 'meetingRooms',
    groupName: 'COMFORT_ROOM',
    priority: 18
  },
  television: {
    code: 'television',
    groupName: 'COMFORT_ROOM',
    priority: 0
  },
  hairDryer: {
    code: 'hairDryer',
    groupName: 'COMFORT_ROOM',
    priority: 3
  },
  minibar: {
    code: 'minibar',
    groupName: 'COMFORT_ROOM',
    priority: 4
  },
  roomService: {
    code: 'roomService',
    groupName: 'COMFORT_ROOM',
    priority: 2
  },
  movieChannels: {
    code: 'movieChannels',
    groupName: 'COMFORT_ROOM',
    priority: 9
  },
  phoneDirDial: {
    code: 'phoneDirDial',
    groupName: 'COMFORT_ROOM',
    priority: 1
  },
  coffee_tea: {
    code: 'coffee_tea',
    groupName: 'COMFORT_ROOM',
    priority: 5
  },
  radio: {
    code: 'radio',
    groupName: 'COMFORT_ROOM',
    priority: 7
  },
  balcony: {
    code: 'balcony',
    groupName: 'COMFORT_ROOM',
    priority: 8
  },
  aircondition: {
    code: 'aircondition',
    groupName: 'COMFORT_ROOM',
    priority: 6
  },
  faxFacInroom: {
    code: 'faxFacInroom',
    groupName: 'COMFORT_ROOM',
    priority: 10
  },
  safeDepBox: {
    code: 'safeDepBox',
    groupName: 'COMFORT_ROOM',
    priority: 11
  },
  rommIron: {
    code: 'rommIron',
    groupName: 'COMFORT_ROOM',
    priority: 12
  },
  shuttle: { groupName: 'SHUTTLE', code: 'shuttle', priority: 0 },
  businessCtr: {
    code: 'businessCtr',
    groupName: 'OTHERS',
    priority: 3
  },
  giftShop: { groupName: 'OTHERS', code: 'giftShop', priority: 2 },
  pharmacy: { groupName: 'OTHERS', code: 'pharmacy', priority: 0 },
  hairdresser: {
    code: 'hairdresser',
    groupName: 'OTHERS',
    priority: 1
  },
  spa: { groupName: 'HEALTHBEATY', code: 'spa', priority: 2 },
  jacuzzi: {
    code: 'jacuzzi',
    groupName: 'HEALTHBEATY',
    priority: 3
  },
  swimmingPoolindoor: {
    code: 'swimmingPoolindoor',
    groupName: 'HEALTHBEATY',
    priority: 4
  },
  sauna: {
    code: 'sauna',
    groupName: 'HEALTHBEATY',
    priority: 5
  },
  solarium: {
    code: 'solarium',
    groupName: 'HEALTHBEATY',
    priority: 1
  },
  beautyParlour: {
    code: 'beautyParlour',
    groupName: 'HEALTHBEATY',
    priority: 6
  },
  massage: {
    code: 'massage',
    groupName: 'HEALTHBEATY',
    priority: 0
  },
  hamam: {
    code: 'hamam',
    groupName: 'HEALTHBEATY',
    priority: 7
  },
  healthClub: {
    code: 'healthClub',
    groupName: 'HEALTHBEATY',
    priority: 8
  },
  childrenClub: {
    code: 'childrenClub',
    groupName: 'FORCHILDREN',
    priority: 2
  },
  childrenActivities: {
    code: 'childrenActivities',
    groupName: 'FORCHILDREN',
    priority: 0
  },
  babySitting: {
    code: 'babySitting',
    groupName: 'FORCHILDREN',
    priority: 1
  },
  beach: { groupName: 'BEACH', code: 'beach', priority: 0 }
}

const HOTEL_SCORE_TYPES: string[] = ['flight', 'hotel', 'room', 'food', 'kids']

const HOTEL_FACILITIES_GROUPED_BY_TYPES: { [key in THotelFacilitiesTypes]: Array<keyof typeof HotelFacilityGroupsTypes> } = {
  Hotel: ['ACTIVITIES', 'COMFORT_HOTEL', 'SHUTTLE', 'OTHERS', 'HEALTHBEATY', 'FORCHILDREN', 'BEACH'],
  Room: ['COMFORT_ROOM']
}

type THotelFacility = IAdditional<THotelFacilitiesTypes, keyof typeof HotelFacilitiesCodes>

interface IPackageHotelFacility extends THotelFacility {
  groupName: IFacilityGroup['groupName']
  priority: IFacilityGroup['priority']
}

interface IFacilityGroup {
  groupName: keyof typeof HotelFacilityGroupsTypes
  code: THotelFacility['code']
  priority: number
}

interface IPackageHotel<T, K> {
  id: string
  supplier: string
  supplierId: string
  name: string
  description: string
  stars: number
  facilities: Array<IAdditional<T, K>>
  images: Array<IAdditional<TImageTypes, string>>
  remarks: IRemark[]
  city: ICity
  country: ICity['country']
  countryName: string
  geo: IGoogleGeo
  geoLocation: IGeolocation
  address: IAddress
  media: IMedia[]
  completion: string
  products: any[]
}

type TPackageHotel = IPackageHotel<THotelFacilitiesTypes, keyof typeof HotelFacilitiesCodes>
type TImageTypes = 'MAIN'

interface IPackageHotelConstructor {
  hotel: TPackageHotel
  minPrice?: string
  departures?: IMinMax<string>
  nights?: IPartOfList[]
}

export interface IAdditional<T, K> {
  id?: string
  type: T
  code?: K
  title: string
  url: string
  createTs?: string
  updateTs?: string
  urlAvailable?: boolean
}

interface IRemark {
  type: string
  text: string
}

interface IGeolocation {
  x: number
  y: number
  coordinates: number[]
  type: string
}

interface IGoogleGeo {
  lat: number
  lng: number
}

interface IAddress {
  city: string
  country?: string
  street: string
  house: string
  zipCode: string
}

interface IPackageHotelClass {
  readonly content: TPackageHotel
  readonly name: string
  readonly description: string
  readonly address: string
  readonly city: TPackageHotel['city']
  readonly country: TPackageHotel['country']
  readonly geo: IGoogleGeo
  readonly remarks: IRemark[]
  readonly hotelId: string
  readonly minProductsNight: number
  readonly images: TPackageHotel['images']
  readonly media: TPackageHotel['media']

  stars: number
  minProductPriceForHotel: string
  departureIntervals: IMinMax<string>
  facilities: IPackageHotelFacility[]

  checkFacilities(): boolean
  getMainImage(): string
  getImages(): Array<{
    url: string
    title: string
  }>
  checkFacilityById({ facilityId }: { facilityId: string }): boolean
  getFacilitiesByType(type: THotelFacilitiesTypes)
  getRemarkByType(type: THotelRemarksTypes)
}

export {
  // classes
  PackageHotel,
  // interfaces
  IPackageHotel,
  IRemark,
  IGeolocation,
  IGoogleGeo,
  IAddress,
  IPackageHotelClass,
  IFacilityGroup,
  IPackageHotelFacility,
  IPackageHotelConstructor,
  // types
  THotelFacility,
  TPackageHotel,
  // consts
  HOTEL_FACILITIES,
  HOTEL_SCORE_TYPES,
  HotelFacilitiesTypes,
  HotelFacilityGroupsTypes,
  HotelFacilitiesCodes,
  HOTEL_FACILITIES_GROUPED_BY_TYPES
}
