import {
  IPackage,
  IPackageClass,
  IPackagesListClass
} from 'modules/product-result.v2/data/product-result.types'

import {
  setClassName
} from 'modules/common/common-methods/common-methods'

import Package from 'modules/product-result.v2/data/package-result/package-result.package/package-result.package.class'

export default class PackagesList implements IPackagesListClass {
  private _packagesList: IPackageClass[] = []
  private _hotelsIds: string[] = []
  private _minPrice: number = null

  constructor(packages: IPackage[], minPrice?: number) {
    setClassName(PackagesList.name, this)

    if (packages) {
      this._packagesList = packages.map(packageData => {
        const newPackage = new Package(packageData)
        this._hotelsIds.push(newPackage.hotel.hotelId)

        return newPackage
      })
    }

    if (minPrice) {
      this._minPrice = minPrice
    }
  }

  get content(): IPackagesListClass['content'] {
    return this._packagesList || null
  }

  get hotelsIds(): string[] {
    return this._hotelsIds
  }

  get minPrice(): number {
    return this._minPrice
  }

  set content(value: IPackagesListClass['content']) {
    this._packagesList = value
  }

  getQuantityOfPackages() {
    return this._packagesList.length
  }

  getPackage({ hotelId }: { hotelId: string }): IPackageClass {
    return (
      this._packagesList?.find(
        packageData => packageData?.hotel.hotelId === hotelId
      ) || null
    )
  }

  getPackageIndex({ hotelId }: { hotelId: string }): number {
    return (
      this._packagesList?.findIndex(
        packageData => packageData?.hotel.hotelId === hotelId
      )
    )
  }

  addPackage({ currentPackage }: { currentPackage: IPackageClass }): void {
    if (this._packagesList) {
      this._packagesList.push(currentPackage)
    } else {
      this._packagesList = Array(currentPackage)
    }
  }

  removePackage({ currentPackage }: { currentPackage: IPackageClass }): void {
    const packageIndex = this.getPackageIndex({ hotelId: currentPackage.hotel.hotelId })

    if (packageIndex !== -1) {
      this._packagesList.splice(packageIndex, 1)
    }
  }
}
