import type {
  CustomerResource
} from 'be-structures/typescript-generator/assembly'

import {
  ICustomerClass,
  ICustomerResource,
  getSymbolNumericRandomKey,
  convertCustomerResourceToICustomerResource,
  convertToCustomerCreate,
  setClassName
} from 'modules/common/common.types';

export class Customer implements ICustomerClass {
  private _customer: ICustomerResource

  constructor(customer?: CustomerResource) {
    setClassName(Customer.name, this)
    
    this._customer = convertCustomerResourceToICustomerResource({ customer })
    this._customer.customerKey = getSymbolNumericRandomKey()
  }

  get content() {
    return this._customer
  }

  get customerId() {
    return this._customer.customerId
  }

  get customerKey() {
    return this._customer.customerKey
  }

  get addresses() {
    return this._customer.addresses
  }

  get media() {
    return this._customer.media
  }

  get info() {
    return this._customer.customerInfo
  }

  get birthday() {
    return this.info.birthday
  }

  get passport() {
    return this._customer.passport
  }

  get fullName(): string {
    if (!this.info) {
      return null
    }
    if (this.info.firstName && this.info.lastName) {
      return `${this.info.lastName} ${this.info.firstName}`
    } else {
      return this.info.firstName || this.info.lastName || null
    }
  }

  get type() {
    return this._customer.type
  }

  set content(customerData: ICustomerResource) {
    this._customer = customerData
  }

  set info(value: ICustomerResource['customerInfo']) {
    this._customer.customerInfo = value
  }

  set passport(value: ICustomerResource['passport']) {
    this._customer.passport = value
  }

  set type(value: ICustomerResource['type']) {
    this._customer.type = value
  }

  set addresses(value: ICustomerResource['addresses']) {
    this._customer.addresses = value
  }

  convertToCustomerCreate() {
    return convertToCustomerCreate({ customer: this._customer })
  }
}
