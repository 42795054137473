import { Store, Module } from 'vuex'

export default ({
  name,
  storeModule,
  http,
  store
}: {
  name: string
  storeModule: (http, store?) => Module<any, any>,
  http,
  store: Store<any>
}): void => {
  if (!store.hasModule(name)) {
    store.registerModule(name, storeModule(http, store))
  }
}
