import * as moment from 'moment'

import {
  IConvertToPackageResult,
  IMinMax,
  setClassName
} from 'modules/common/common.types'

import {
  IPartOfList,
  IProductFilter,
  IProductFilterClass,
  THotelFacility
} from 'modules/product-result.v2/data/product-result.types'
import {SupplierPriceResource} from "be-structures/typescript-generator";

export default class ProductFilter implements IProductFilterClass {
  private _dealFeatures: string[] = []
  private _page: number = 0
  private _facilities: string[] = []
  private _hotelsNames: string[] = []
  private _selectedHotel: SupplierPriceResource[] = []
  private _durationFormat: string = '[PT]H[H]m[M]s[S]'
  private _outDepartureMinutes: [number, number] = [0, 1439]
  private _inDepartureMinutes: [number, number] = [0, 1439]
  private _outArrivalMinutes: number[] = [0, 1439]
  private _inArrivalMinutes: number[] = [0, 1439]

  private _filter: IProductFilter = null

  constructor(filter: IProductFilter) {
    setClassName(ProductFilter.name, this)
    this._filter = JSON.parse(JSON.stringify(filter))
  }

  get content(): IProductFilterClass['content'] {
    return this._filter
  }
  get airlines(): IPartOfList[] {
    return this._filter.airlines || []
  }
  get airlinesCodes(): string[] {
    return this.airlines.map(airline => airline.code.code) || []
  }
  get dealFeatures(): string[] {
    return this._filter.dealFeatures
  }
  get dealFeaturesCurrent(): string[] {
    return this._dealFeatures
  }
  get totalPages(): number {
    return this._filter.totalPages
  }
  get page(): number {
    return this._page
  }
  get boarding(): string[] {
    return this._filter.boarding || []
  }
  get hotelFacilities(): THotelFacility[] {
    return this._filter.hotelFacilities
  }
  get hotelFacilitiesCodes(): string[] {
    return this._filter.hotelFacilities.map(facility => facility.code) || []
  }
  get hotelFacilitiesIds(): string[] {
    return this._filter.hotelFacilities.map(facility => facility.id) || []
  }
  get price(): [number, number] {
    return this.getFilterConditionValue(this._filter.price)
  }
  get hotels() {
    return this._filter.hotels
  }
  get hotelsNames(): string[] {
    return this._hotelsNames
  }
  get selectedHotel(): SupplierPriceResource[] {
    return this._selectedHotel
  }
  get hotelStars(): [number, number] {
    return this.getFilterConditionValue(this._filter.hotelStars)
  }
  get providers(): IPartOfList[] {
    return this._filter.providers
  }
  get providersCodes(): string[] {
    return this.providers.map(provider => provider.code.code) || []
  }
  get inFlightTime(): number[] {
    return this.getFilterDurationConditionValue(this._filter.inFlightTime)
  }
  get outFlightTime(): number[] {
    return this.getFilterDurationConditionValue(this._filter.outFlightTime)
  }
  get connectionTime(): number[] {
    return this.getFilterDurationConditionValue(this._filter.connectionTime)
  }
  get outDeparture(): [number, number] {
    return this._outDepartureMinutes
  }
  get inDeparture(): [number, number] {
    return this._inDepartureMinutes
  }
  get outArrival(): number[] {
    return this._outArrivalMinutes
  }
  get inArrival(): number[] {
    return this._inArrivalMinutes
  }

  set hotels(value) {
    this._filter.hotels = value
  }
  set content(value: IProductFilterClass['content']) {
    this._filter = value
  }
  set airlines(value: IPartOfList[]) {
    this._filter.airlines = value
  }
  set providers(value: IPartOfList[]) {
    this._filter.providers = value
  }
  set dealFeaturesCurrent(val: string[]) {
    this._dealFeatures = val
  }
  set page(value: number) {
    this._page = value
  }
  set boarding(value: string[]) {
    this._filter.boarding = value
  }
  set facilities(value: string[]) {
    this._facilities = value
  }
  set hotelFacilities(value: THotelFacility[]) {
    this._filter.hotelFacilities = value
  }
  set price(value: [number, number]) {
    this._filter.price = this.convertFilterConditionValue(value)
  }

  set selectedHotel(value: SupplierPriceResource[]) {
    this._selectedHotel = value
  }

  set hotelsNames(value: string[]) {
    this._hotelsNames = value
  }
  set hotelStars(value: [number, number]) {
    this._filter.hotelStars = this.convertFilterConditionValue(value)
  }
  set inFlightTime(value: number[]) {
    this._filter.inFlightTime = this.convertFilterDurationConditionValue(value)
  }
  set outFlightTime(value: number[]) {
    this._filter.outFlightTime = this.convertFilterDurationConditionValue(value)
  }
  set connectionTime(value: number[]) {
    this._filter.connectionTime = this.convertFilterDurationConditionValue(
      value
    )
  }
  set outDeparture(value: [number, number]) {
    this._outDepartureMinutes = value
  }
  set inDeparture(value: [number, number]) {
    this._inDepartureMinutes = value
  }
  set outArrival(value: number[]) {
    this._outArrivalMinutes = value
  }
  set inArrival(value: number[]) {
    this._inArrivalMinutes = value
  }

  getFilterConditionValue(value: IMinMax<number>): [number, number] {
    return [Number(value.min), Number(value.max)]
  }

  getFilterDurationConditionValue(value: IMinMax<string>): number[] {
    return new Array(
      moment.duration(value.min).asMinutes(),
      moment.duration(value.max).asMinutes()
    )
  }

  isEmptyConditionValue(conditionValue: number[]): boolean {
    return conditionValue[1] - conditionValue[0] === 0 ? true : false
  }

  convertMinutesToHHmm(minutes: number): string {
    return moment
      .utc()
      .startOf('day')
      .add({ minutes })
      .format('HH:mm')
  }

  convertMinutesToDuration(minutes: number): string {
    return moment
      .utc(moment.duration(minutes, 'minutes').asMilliseconds())
      .format(this._durationFormat)
  }

  convertMinutesToHM(minutes: number): string {
    return moment
      .utc(moment.duration(minutes, 'minutes').asMilliseconds())
      .format('H[h] m[m]')
  }

  convertFilterConditionValue<T>(value: T[]): IMinMax<T> {
    return {
      max: value[1],
      min: value[0]
    }
  }

  convertFilterDurationConditionValue(value: number[]): IMinMax<string> {
    return {
      max: this.convertMinutesToDuration(value[1]),
      min: this.convertMinutesToDuration(value[0])
    }
  }

  convertFilterTimeConditionValue(value: number[]): IMinMax<string> {
    return {
      max: this.convertMinutesToHHmm(value[1]),
      min: this.convertMinutesToHHmm(value[0])
    }
  }

  convertFilterToPackageResult(): IConvertToPackageResult {
    return {
      airlines: this.airlinesCodes,
      boarding: this.boarding,
      connectionTime: this.convertFilterDurationConditionValue(
        this.connectionTime
      ),
      dealFeatures: this.dealFeaturesCurrent,
      hotelFacilityIds: this._facilities,
      hotelNames: this._hotelsNames,
      hotelStars: this.convertFilterConditionValue(this.hotelStars),
      inArrival: this.convertFilterTimeConditionValue(this.inArrival),
      inDeparture: this.convertFilterTimeConditionValue(this.inDeparture),
      inFlightTime: this.convertFilterDurationConditionValue(this.inFlightTime),
      outArrival: this.convertFilterTimeConditionValue(this.outArrival),
      outDeparture: this.convertFilterTimeConditionValue(this.outDeparture),
      outFlightTime: this.convertFilterDurationConditionValue(
        this.outFlightTime
      ),
      page: this.page,
      price: this.convertFilterConditionValue(this.price),
      providers: this.providersCodes
    }
  }
}
