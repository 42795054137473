import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import dayjs from 'dayjs'
import cloneDeep from 'lodash/cloneDeep'

import funcAPI from 'modules/product-search.v2/product-search.api'

import {
  IATACityResource,
  IATACountryResource,
  PriceCalendarPeriodResource
} from 'be-structures/typescript-generator/assembly'

import {
  IConvertedQuery,
  IConvertToPackageResult,
  IVacationTerm,
  TFlightGroups
} from 'modules/common/common.types'

import {
  formatDateToYMD
} from 'common/filters/formattedDates'

import {
  IQueryLocation,
  PackageCompareQuery,
  SearchPendingsNames,
  TSearchPendings,
  IPriceCalendarPeriod,
  IPackageCompareQueryClass,
  ICountriesWithDigest,
  ICitiesWithDigest,
  IQueryDestinations,
  IPackageCompareQuery, IReferralRule
} from 'modules/product-search.v2/data/product-search.types'
import {Store} from "vuex";

export const PRODUCT_SEARCH_NAME = 'PRODUCT_SEARCH_MODULE'

export const PRODUCT_SEARCH = (http: any, store: Store<any>) => {
  const api = funcAPI(http)
  @Module({ name: PRODUCT_SEARCH_NAME, namespaced: true, store: store })
  class ProductSearchModule extends VuexModule {
    private _pendings: TSearchPendings = {
      getCountries: true,
      getAndSetCountries: false,
      getCities: true,
      getAndSetCities: true,
      bestDeals: false,
      getDepartureDatesByCountry: false,
      getReturnDatesByCountry: false,
      getAndSetDepartureDatesByCountry: false,
      getAndSetReturnDatesByCountry: false
    }
    private _searchQuery: IPackageCompareQueryClass = null
    private _searchQuerySnapshot: IPackageCompareQueryClass = null
    private _countriesCodes: string[] = []
    private _destinations: IQueryDestinations = null
    private _destinationsSnapshot: IQueryDestinations = null
    private _departureDatesByCountry: IPriceCalendarPeriod = null
    private _returnDatesByCountry: IPriceCalendarPeriod = null
    private _bestDeals: any = []
    private _referralRule: IReferralRule = null


    get referralRule() {
      return this._referralRule
    }
    get pendings() {
      return this._pendings
    }
    get searchQuery() {
      return this._searchQuery
    }
    get searchQuerySnapshot() {
      return this._searchQuerySnapshot
    }
    get bestDeals() {
      return this._bestDeals
    }

    get departureDatesByCountry() {
      return this._departureDatesByCountry
    }

    get returnDatesByCountry() {
      return this._returnDatesByCountry
    }

    get destinations() {
      return this._destinations
    }

    get destinationsSnapshot() {
      return this._destinationsSnapshot
    }

    get countriesCodes() {
      return this._countriesCodes
    }

    @Mutation
    setReferralRule({referralRule}: {referralRule: IReferralRule}) {
      this._referralRule = referralRule
    }

    @Mutation
    resetStore() {
      this._pendings = {
        getCountries: true,
        getAndSetCountries: true,
        getCities: true,
        getAndSetCities: true,
        bestDeals: false,
        getDepartureDatesByCountry: false,
        getReturnDatesByCountry: false,
        getAndSetDepartureDatesByCountry: false,
        getAndSetReturnDatesByCountry: false
      }
      this._searchQuery = null
      this._searchQuerySnapshot = null
      this._departureDatesByCountry = null
      this._returnDatesByCountry = null
      this._destinations = null
      this._destinationsSnapshot = null
      this._countriesCodes = []
    }



    @Mutation
    setSearchQuery(searchQuery: IPackageCompareQueryClass) {
      this._searchQuery = searchQuery
    }

    @Mutation
    initializeSearchQuery({ searchQuery }: { searchQuery: IPackageCompareQuery }) {
      this._searchQuery = new PackageCompareQuery(cloneDeep(searchQuery))
    }

    @Mutation
    setSearchQuerySnapshot({ searchQuery }: { searchQuery: IPackageCompareQuery }) {
      this._searchQuerySnapshot = new PackageCompareQuery(cloneDeep(searchQuery))
    }

    @Mutation
    takeSearchQuerySnapshot() {
      this._searchQuerySnapshot = new PackageCompareQuery(cloneDeep(this._searchQuery.content))
      this._destinationsSnapshot = cloneDeep(this._destinations)
    }

    @Mutation
    applySearchQuerySnapshot() {
      this._searchQuery = new PackageCompareQuery(cloneDeep(this._searchQuerySnapshot.content))
      this._destinations = this._destinationsSnapshot
    }

    @Mutation
    setPending({ pendingName, value }: { pendingName: keyof typeof SearchPendingsNames, value: boolean }) {
      this._pendings[pendingName] = value
    }

    @Mutation
    setDestinations(destinations: IQueryDestinations, updateCountriesCodes?: boolean) {
      this._destinations = destinations || {}

      if (updateCountriesCodes) {
        this._countriesCodes = destinations ? [...new Set(Object.keys(destinations || {}))] : []
      }
    }

    @Mutation
    setDatesByCountry({ flightGroup, dates }: { flightGroup: TFlightGroups, dates: IPriceCalendarPeriod }) {
      if (flightGroup === 'departures') {
        this._departureDatesByCountry = dates
        this._returnDatesByCountry = null
      } else if (flightGroup === 'returns') {
        this._returnDatesByCountry = dates
      }
    }

    @Mutation
    setBestDeals({ bestDeals }: { bestDeals: any[] }) {
      this._bestDeals = bestDeals
    }


    @Action
    async getReferralRule({referralCode}: {referralCode: string}) {
      try {
        const referralRule = await api.getReferralRule({referralCode})

        this.context.commit('setReferralRule', { referralRule })
      } catch (e) {
        return console.error(e)
      }
    }


    @Action
    setDestination<K extends keyof IQueryLocation>({
      countryCode,
      propertyName,
      value
    }: {
      countryCode: string
      propertyName: K
      value: IATACountryResource | IATACityResource[] | string
    }) {
      const destinations = {
        ...this._destinations,
        [countryCode]: {
          ...this._destinations[countryCode],
          [propertyName]: value
        }
      }

      this.context.commit('setDestinations', destinations)
    }

    @Action
    clearDestinations() {
      this.context.commit('setDestinations', null)
    }

    @Action
    initializeDestinations({ countries }: { countries: IATACountryResource[] }) {
      if (countries?.length > 0) {
        this.clearDestinations()
        countries.map(country => {
          this.setDestination({
            countryCode: country.code,
            propertyName: 'country',
            value: country
          })
        })
      }
    }

    @Action
    async getBestDeals() {
      // this.context.commit('setPending', { pendingName: 'bestDeals', value: true })
      // const query = new PackageCompareQuery()
      // const bestDeals = await api.getBestDeals({ query })
      // this.context.commit('setBestDeals', { bestDeals })
      // Promise.resolve()
      // this.context.commit('setPending', { pendingName: 'bestDeals', value: false })
    }

    @Action
    async getDestinationCountries({
      filter
    }: {
      filter?: IConvertToPackageResult
    }): Promise<ICountriesWithDigest> {
      try {
        this.context.commit('setPending', { pendingName: 'getCountries', value: true })

        const { content, page } = await api.getActiveCountries({
          query: this._searchQuery.convertToRequest(),
          filter
        })

        return { countries: content, digest: page.effectiveDigest }
      } finally {
        this.context.commit('setPending', { pendingName: 'getCountries', value: false })
      }
    }

    @Action
    async getDestinationCountriesForCalendar() {
      try {
        this.context.commit('setPending', { pendingName: 'getCountries', value: true })

        return api.getActiveCountriesForCalendar()
      } finally {
        this.context.commit('setPending', { pendingName: 'getCountries', value: false })
      }
    }

    @Action
    async getAndSetDestinationCountriesWithDigest({
      filter
    }: {
      filter?: IConvertToPackageResult
    }) {
      try {
        this.context.commit('setPending', { pendingName: 'getAndSetCountries', value: true })
        this.clearDestinations()
        const { countries, digest } = await this.getDestinationCountries({ filter })
        this.initializeDestinations({ countries })

        return { countries, digest }
      } finally {
        this.context.commit('setPending', { pendingName: 'getAndSetCountries', value: false })
      }
    }

    @Action
    async getAndSetDestinationCountriesForCalendar() {
      try {
        this.context.commit('setPending', { pendingName: 'getAndSetCountries', value: true })
        this.clearDestinations()
        const countries = await this.getDestinationCountriesForCalendar()
        this.initializeDestinations({ countries })

        return countries
      } finally {
        this.context.commit('setPending', { pendingName: 'getAndSetCountries', value: false })
      }
    }

    @Action
    async getDestinationCities({
      filter,
      query
    }: {
      filter?: IConvertToPackageResult,
      query: IConvertedQuery
    }): Promise<ICitiesWithDigest> {
      try {
        this.context.commit('setPending', { pendingName: 'getCities', value: true })

        if (!query.countryCode) {
          throw new Error('Selected country code is empty')
        }

        const data = await api.getActiveCities({ query, filter })

        return { cities: data.content, digest: data.page.effectiveDigest }
      } finally {
        this.context.commit('setPending', { pendingName: 'getCities', value: false })
      }
    }

    @Action
    async getAndSetDestinationCitiesWithDigest({
      filter,
      query
    }: {
      filter?: IConvertToPackageResult,
      query: IConvertedQuery
    }) {
      try {
        this.context.commit('setPending', { pendingName: 'getAndSetCities', value: true })

        const { cities, digest } = await this.getDestinationCities({ filter, query })
      
        if (cities?.length > 0) {
          this.setDestination({
            countryCode: query.countryCode,
            propertyName: 'cities',
            value: cities
          })
        }

        return { cities, digest }
      } finally {
        this.context.commit('setPending', { pendingName: 'getAndSetCities', value: false })
      }
    }

    @Action
    async getDatesByCountry({
      countryCode,
      flightGroup,
      departureDate,
      cityCode
    }: {
      countryCode: string
      flightGroup: TFlightGroups
      departureDate?: string,
      cityCode?: string
    }): Promise<PriceCalendarPeriodResource[]> {
      const pendingName: keyof typeof SearchPendingsNames = flightGroup === 'departures' ? 'getDepartureDatesByCountry' : 'getReturnDatesByCountry'

      try {
        let result = null

        this.context.commit('setPending', { pendingName, value: true })

        if (flightGroup === 'departures') {
          result = await api.getDepartureDatesByCountry({ countryCode })
        } else if (flightGroup === 'returns') {
          result = await api.getReturnDatesByCountry({ cityCode, departureDate })
        }

        return result
      } finally {
        this.context.commit('setPending', { pendingName, value: false })
      }
    }

    @Action
    async getAndSetDatesByCountry({
      countryCode,
      flightGroup,
      departureDate,
      cityCode
    }: {
      countryCode: string
      flightGroup: TFlightGroups
      departureDate?: string
      cityCode?: string
    }): Promise<IPriceCalendarPeriod> {
      const pendingName: keyof typeof SearchPendingsNames = flightGroup === 'departures' ? 'getAndSetDepartureDatesByCountry' : 'getAndSetReturnDatesByCountry'

      try {
        this.context.commit('setPending', { pendingName, value: true })

        const dates = await this.getDatesByCountry({ countryCode, flightGroup, departureDate, cityCode })

        if (dates.length > 0) {
          const restucturedDates = dates.reduce((result: IPriceCalendarPeriod, data: PriceCalendarPeriodResource) => {
            const date = dayjs(flightGroup === 'departures' ? data.departureDate : data.returnDate)
            const monthNumber = date.month() + 1
            return {
              ...result,
              [monthNumber]: {
                ...(result[monthNumber] || {}),
                [formatDateToYMD(date.toDate())]: data
              }
            }
          }, {})

          this.context.commit('setDatesByCountry', { flightGroup, dates: restucturedDates })

          return restucturedDates
        }

        return null
      } finally {
        this.context.commit('setPending', { pendingName, value: false })
      }
    }
  }

  return ProductSearchModule
}
