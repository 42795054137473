import dayjs from 'dayjs'

import formatDate from 'common/filters/formatDate'
import countTime from 'common/filters/countTime'

export const formatDateToYMD = (value: string | Date) => formatDate(value, 'YYYY-MM-DD')

export const formatTodayToYMD = () => formatDate(dayjs().toString(), 'YYYY-MM-DD')

export const formatDateToDMYDot = (value: string | Date) => formatDate(value, 'DD.MM.YYYY')

export const formatDateToDMYShortDot = (value: string | Date) => formatDate(value, 'DD.MM.YY')

export const formatDateToDMonthY = (value: string | Date) => formatDate(value, 'D MMMM YYYY')

export const formatDateToDMDot = (value: string | Date) => formatDate(value, 'DD.MM')

export const formatDateToHHmm = (value: string | Date) => formatDate(value, 'HH:mm')

export const formatFlightTime = (value: string) => countTime(value)
