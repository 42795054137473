enum AppMode {
  'NORMAL' = 'NORMAL',
  'PACKAGE_SELECTION' = 'PACKAGE_SELECTION'
}

enum CompareAppPendingsNames {
  'initialCountriesList',
  'initialHotelsList',
  'initialFilter'
}

export {
  AppMode,
  CompareAppPendingsNames
}