import { PriceResource } from 'be-structures/typescript-generator/assembly';

import {
  IPackageProduct,
  IPackageProductClass,
  IPackageProductsListClass,
} from 'modules/product-result.v2/data/product-result.types'

import PackageProduct from 'modules/product-result.v2/data/package-result/package-result.product/package-result.product.class'

import {
  setClassName
} from 'modules/common/common-methods/common-methods'

export default class PackageProductsList implements IPackageProductsListClass {
  private _initialPackageProductsList: IPackageProductClass[] = []
  private _packageProductsList: IPackageProductClass[] = []
  private _productsIds: IPackageProductsListClass['productsIds'] = []
  private _productsFareBasisList: string[] = []
  private _productsProvidersList: string[] = []

  // temporary
  private _prices: PriceResource[] = []

  constructor(private _packageProductsListData: IPackageProduct[]) {
    setClassName(PackageProductsList.name, this)

    this._packageProductsList = this._packageProductsListData.map(product => {
      this._productsIds.push(product.productId)
      this.pushToProductsFareBasisList(product.fareBasis)
      this.pushToProductsProvidersList(product.provider.name)
      return new PackageProduct(product)
    })
    this._initialPackageProductsList = this._packageProductsList
  }

  get content(): IPackageProductsListClass['content'] {
    return this._packageProductsList
  }
  get initialContent(): IPackageProductsListClass['initialContent'] {
    return this._initialPackageProductsList
  }
  get productsIds(): IPackageProductsListClass['productsIds'] {
    return this._productsIds
  }
  get pricesList() {
    return this._prices
  }
  get productsFareBasis(): string[] {
    return this._productsFareBasisList
  }
  get productsProviders(): string[] {
    return this._productsProvidersList
  }

  set pricesList(value: PriceResource[]) {
    this._prices = value
  }
  set productsFareBasis(value: string[]) {
    this._productsFareBasisList = value
  }
  set productsProviders(value: string[]) {
    this._productsProvidersList = value
  }

  getProductsQuantity(): number {
    return this._packageProductsListData?.length
  }

  filterProducts({
    fareBasisList,
    fastOkStatus,
    providersList
  }: {
    fareBasisList: string[]
    fastOkStatus: boolean
    providersList: string[]
  }): void {
    this._packageProductsList = this._initialPackageProductsList.filter(
      product => {
        const findedFareBasis: number = fareBasisList.indexOf(
          product.content.fareBasis
        )
        const findedProvider: number = providersList.indexOf(
          product.content.provider.name
        )
        const matchFastOk: boolean = product.isFastOk() === fastOkStatus

        if (
          findedFareBasis !== -1 &&
          findedProvider !== -1 &&
          (matchFastOk || !fastOkStatus)
        ) {
          return product
        }
      }
    )
  }

  getProduct(productId: string): IPackageProductClass {
    return (
      this._packageProductsList.find(
        productData => productData.content.productId === productId
      ) || null
    )
  }

  getProductsGroupedByProvider(): { [key: string]: IPackageProductClass[] } {
    return this.content.reduce((productA: { [key: string]: IPackageProductClass[] }, productB) => {
      const providerCode: string = productB.content.provider.code
      
      return {
        ...productA,
        [providerCode]: [
          ...(Object.keys(productA).includes(providerCode) ? productA[providerCode] : []),
          productB
        ]
      }
    }, {})
  }

  pushToProductsFareBasisList(fb: string): void {
    const indexDublicateFB: number = this._productsFareBasisList.indexOf(fb)

    if (indexDublicateFB === -1) {
      this._productsFareBasisList.push(fb)
    }
  }

  pushToProductsProvidersList(fb: string): void {
    const indexDublicateFB: number = this._productsProvidersList.indexOf(fb)

    if (indexDublicateFB === -1) {
      this._productsProvidersList.push(fb)
    }
  }

  getProductsFilteredByFareBasis(fareBasis: string) {
    return this._packageProductsList.filter(product => product.fareBasis === fareBasis)
  }
}
