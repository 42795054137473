

















import ModalWrapperMobile from "ui-modules/modals/mobile/modal-wrapper-mobile/modal-wrapper-mobile.vue";
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
@Component({
  components: {
    ModalWrapperMobile
  }
})
export default class ModalReferralGreetings extends Vue{
  @Prop(Object) referral: string
}
