import {
  IMinMax
} from 'modules/common/common.types'

import {
  HOTEL_FACILITIES,
  IFacilityGroup,
  IGoogleGeo,
  IMedia,
  IPackageHotelClass,
  IPackageHotelConstructor,
  IPackageHotelFacility,
  IPartOfList,
  IRemark,
  THotelFacility,
  TPackageHotel,
  THotelRemarksTypes,
  THotelFacilitiesTypes,
} from 'modules/product-result.v2/data/product-result.types'

import {
  setClassName
} from 'modules/common/common-methods/common-methods'

export default class PackageHotel implements IPackageHotelClass {
  private _facilities: IPackageHotelFacility[] = null
  private _hotel: TPackageHotel = null

  private _minProductPriceForHotel: string = null
  private _departureIntervals: IMinMax<string> = null
  private _nightsForAllProducts: IPartOfList[] = []

  constructor(hotelData: IPackageHotelConstructor) {
    setClassName(PackageHotel.name, this)

    this._hotel = hotelData.hotel
    this._facilities = this._hotel.facilities.map(facility =>
      Object.assign({
        ...facility,
        ...this._getGroupForFacility(facility),
        ['createTs']: null,
        ['updateTs']: null
      })
    )

    this._minProductPriceForHotel = hotelData.minPrice
    this._departureIntervals = hotelData.departures
    this._nightsForAllProducts = hotelData.nights
  }
  
  get content(): IPackageHotelClass['content'] {
    return this._hotel
  }
  get name(): string {
    return this._hotel.name
  }
  get description(): string {
    return this._hotel.description || null
  }
  get stars(): number {
    return this._hotel.stars
  }
  get city(): TPackageHotel['city'] {
    return this._hotel.city
  }
  get country(): TPackageHotel['country'] {
    return this._hotel.city.country
  }
  get address(): string {
    return (this._hotel.address && this._hotel.address.street) || null
  }
  get geo(): IGoogleGeo {
    return this._hotel.geoLocation &&
      this._hotel.geoLocation.y &&
      this._hotel.geoLocation.x
      ? {
          lat: this._hotel.geoLocation.y,
          lng: this._hotel.geoLocation.x
        }
      : null
  }
  get media(): TPackageHotel['media'] {
    return this._hotel.media
  }
  get images(): TPackageHotel['images'] {
    return this._hotel.images
  }
  get facilities(): IPackageHotelFacility[] {
    return this._facilities
  }
  get remarks(): IRemark[] {
    return this._hotel.remarks
  }
  get hotelId(): string {
    return this._hotel.id
  }
  get minProductPriceForHotel(): string {
    return this._minProductPriceForHotel
  }
  get departureIntervals(): IMinMax<string> {
    return this._departureIntervals
  }
  get minProductsNight(): number {
    return Number(this._nightsForAllProducts[0].code.code)
  }

  set minProductPriceForHotel(value: string) {
    this._minProductPriceForHotel = value
  }
  set departureIntervals(value: IMinMax<string>) {
    this._departureIntervals = value
  }
  set facilities(value: IPackageHotelFacility[]) {
    this._facilities = value
    this._hotel.facilities = value
  }
  set stars(value: number) {
    this._hotel.stars = value
  }

  checkFacilities(): boolean {
    return this._hotel.facilities.length > 0
  }
  getImages(): Array<{
    url: string
    title: string
  }> {
    const res = this._hotel.images.filter(image => image.urlAvailable)
    return res.map(image => {
      return {
        title: image.title,
        url: image.url
      }
    })
  }
  getMainImage(): string {
    return (this._hotel.images.length && 
      this._hotel.images.find(image => {
        return image.type === 'MAIN' && image.urlAvailable 
      })?.url) || null
  }
  checkFacilityById({ facilityId }: { facilityId: string }): boolean {
    return Boolean(
      this._facilities.find(facility => facility.id === facilityId)
    )
  }
  getFacilitiesByType(type: THotelFacilitiesTypes) {
    return this._hotel.facilities?.filter(facility => facility.type === type)
  }
  getRemarkByType(type: THotelRemarksTypes) {
    return this._hotel.remarks?.filter(remark => remark.type === type)[0]?.text || ''
  }
  private _getGroupForFacility(facility: THotelFacility): IFacilityGroup {
    return HOTEL_FACILITIES[facility.code] || null
  }
}
