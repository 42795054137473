import type {
  TravelOrderResource
} from 'be-structures/typescript-generator/assembly'

import {
  IOrderClass,
  IOrderResource,
  convertTravelOrderResourceToIOrderResource
} from 'modules/product-order.v2/data/order/order.types'

import {
  setClassName
} from 'modules/common/common-methods/common-methods'

export class Order implements IOrderClass {
  private _order: IOrderResource = null 

  constructor(order?: TravelOrderResource) {
    setClassName(Order.name, this)

    this._order = convertTravelOrderResourceToIOrderResource({ order })
  }

  get content() {
    return this._order;
  }

  get orderId() {
    return this._order.entityId
  }

  get number() {
    return this._order.number
  }

  get customers() {
    return this._order.customers;
  }

  get contactPerson() {
    return this._order.contactPerson;
  }

  get paxPrice() {
    return this._order.paxPrice;
  }

  get totalPrice() {
    return this._order.totalPrice;
  }

  get items() {
    return this._order.items
  }

  set totalPrice(value: IOrderResource['totalPrice']) {
    this._order.totalPrice = value
  }

  set contactPerson(value) {
    this._order.contactPerson = value
  }

  set items(value) {
    this._order.items = value
  }
}
