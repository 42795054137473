import { Store } from 'vuex'

import registerStoreModule from 'modules/common/common-methods/register-store-module'
import { PRODUCT_RESULT_NAME, PRODUCT_RESULT } from './product-result.store'

export default (http, store: Store<any>): void => {
  registerStoreModule({
    name: PRODUCT_RESULT_NAME,
    storeModule: PRODUCT_RESULT,
    http,
    store
  })
}