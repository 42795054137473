










import  { Component, Mixins } from 'vue-property-decorator';

import RouterMixin from 'applications/common/mixins/router-mixin'
import PwaPopUp from "applications/mobile/components/root-app/PwaPopUp.vue";
import AccessBtn from "applications/desktop/accessibility/AccessBtn.vue";
import AccessPopup from "applications/desktop/accessibility/AccessPopup.vue";
import {Accessibility} from "applications/desktop/accessibility/accessibility";
import ModalReferralGreetings from "ui-modules/modals/mobile/modal-referral-greetings/modal-referral-greetings.vue"

import {PRODUCT_ORDER_NAME} from "../../../../modules/product-order.v2/product-order.store";
import getStoreModules from "../../../common/store/storeModules";
import getHTTP from "../../../../common/http/http";
import PrepareSearchDataCommon from "../../../common/components/prepare-search-data/prepare-search-data.common";

@Component({
  components: {AccessPopup, AccessBtn, PwaPopUp, ModalReferralGreetings}
})
export default class PackageCompareMobileApp extends Mixins(RouterMixin, PrepareSearchDataCommon) {
  startAccess = new Accessibility();

  mounted() {
    if(!!this.getRefCode) {
      this.storeModules.PRODUCT_SEARCH.getReferralRule({referralCode: this.getRefCode})
    }
  }


  get getRefCode() {
    return new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => typeof prop === 'string' ? searchParams.get(prop) : null,
    }).ref;
  }

  get getReferral() {
    return this.storeModules.PRODUCT_SEARCH.referralRule
  }

  get currentLocale() {
    return this.storeModules.i18n.currentLocale
  }

  get welcomeMessage() {
    if(this.getReferral.condition.childConditions.length) {
      return this.getReferral.condition.childConditions[0].welcomeMessage[this.currentLocale.toLocaleUpperCase()]

    } else {
      return this.$t('common.referral.no-message')
    }
  }
  closeReferralModal() {
    this.storeModules.PRODUCT_SEARCH.setReferralRule({referralRule: null})
  }

}
