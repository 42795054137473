import type {
  PriceResource
} from 'be-structures/typescript-generator/assembly'

import {
  IPriceClass,
  IMoneyResource,
  IPriceResource
} from 'modules/common/price/price.types'

import {
  convertPriceResourceToIPriceResource
} from 'modules/common/price/price.conveter'

import {
  setClassName
} from 'modules/common/common.types'

export class Price implements IPriceClass {
  private _price: IPriceResource = null 

  constructor(price?: PriceResource) {
    setClassName(Price.name, this)

    this._price = convertPriceResourceToIPriceResource({ price })
  }

  get content() {
    return this._price;
  }

  get currency() {
    return this._price.currency;
  }

  get fare() {
    return this._price.fare;
  }

  get tax() {
    return this._price.tax;
  }

  get total() {
    return this._price.total;
  }

  set currency(value: IPriceResource['currency']) {
    this._price.currency = value
  }

  set total(value: IPriceResource['total']) {
    this._price.total = value
  }

  getTotalPriceResource(): IMoneyResource {
    return {
      currency: this._price.currency,
      amount: this._price.total
    }
  }
}
