import {
  ITravelProductPriceResource,
} from 'modules/product-result.v2/data/product-result.types';

import {
  PRICE_BLANK
} from 'modules/common/common.types'

export const PRODUCT_PRICE_BLANK: ITravelProductPriceResource = {
  ...PRICE_BLANK,
  paxPrices: [],
  markup: null,
  commission: null
}