export * from 'modules/product-result.v2/data/product-result.consts'
export * from 'modules/product-result.v2/data/package-result/package-result.types'
export * from 'modules/fly-search/types/fly-search.types'
export * from 'modules/product-result.v2/data/product-filter/product-filter.types'
export * from 'modules/product-result.v2/data/package-compare/package-compare.filter/filter.types'
export * from 'modules/product-search.v2/data/product-search.types'
export * from 'modules/product-result.v2/data/package-result/package-result.types'
export * from 'modules/product-result.v2/data/package-compare/package-compare.result/result.types'
export * from 'modules/product-result.v2/data/package-compare/package-compare.results-list/results-list.types'

import {
  SegmentResource
} from 'be-structures/typescript-generator/assembly'

import {
  IMinMax,
  IPaxes,
  IResponseWithDigest
} from 'modules/common/common.types'

import {
  IPackageCompare
} from 'modules/product-result.v2/data/package-compare/package-compare.result/result.types'

import {  
  IPackageCompareResultsList,
} from 'modules/product-result.v2/data/package-compare/package-compare.results-list/results-list.types'

import {
  ICity
} from 'modules/fly-search/types/fly-search.types'

import {
  IPackage,
  IPackageClass,
  IPackageHotelFacility,
  IPackagesListClass,
} from 'modules/product-result.v2/data/package-result/package-result.types'

import {
  IProductFilter,
} from 'modules/product-result.v2/data/product-filter/product-filter.types'


export interface IDestinationsPackages { [key: string]: IPackagesListClass }
export interface IAllDestinationsInfo { [key: string]: IAllDestinationInfo }

export interface IAllDestinationInfo {
  minPrice: number
  quantityOfPackages: number
}

export interface IAirline {
  code: string
  name: string
}

export interface ICodeName {
  code: string
  name: string
}
export interface IBestDeal {
  city: ICity
  codeShareCarrier: ICodeName
  dealFeatures: DealFeature
  dealInterval: IMinMax<string>
  fareBasis: string
  hotel: IHotel
  nights: number
  price: {
    total: string
  }
  productId: string
  segmentGroups: ISegmentGroupResource[]
  supplierAirline: ICodeName
  roomType: string
}

// structure is duplicated in the fly-result, package-result modules
export interface IAirlineGroup {
  airline: IAirline
  groupSize: number
  price: string
}

export interface IResultCodeSegment {
  fromAirport: string
  toAirport: string
  departureDate: string
}

export interface IResultQueryBody {
  flexDays: number
  flightClass: string
  maxStops: number
  paxes: IPaxes
  segments: IResultCodeSegment | IResultCodeSegment[]
}

export interface IQuery {
  body: IResultQueryBody
  expireAt: string
  effectiveDigest: IProductFilter
  productType: string
  requestId: string
  searchStatus: string
  digest: IProductFilter
}

export interface IPage {
  effectiveDigest: IProductFilter
  number: number
  size: number
  totalElements: number
  totalPages: number
  query: IQuery
}

// <----- PACKAGE RESULTS ----->

export interface IPackageResponse {
  content?: IPackage[]
  page?: IPage
}

export interface IPackageCompareResponse {
  content?: IPackageCompare[]
  page?: IPage
}

export interface IPackageAutoFillForms {
  currentFlowIsAutomatic: boolean
  isActive: boolean
  isAutoFilledFormIndex: number[]
  [key: string]: boolean | number[]
}

export interface IPackageResultState {
  currentPackage: IPackageClass
}

export interface IPackageCompareResultState {
  // classic
  currentPackage: IPackageClass
  // compare
  autoFillForms: IPackageAutoFillForms
  disabledFacilitiesList: IPackageHotelFacility[]
  dontShowHotelSelectAdvice: boolean
  isPendingProducts: boolean
  results: IPackageCompareResultsList
  favoriteResults: IPackageCompareResultsList
  comparableResults: IPackageCompareResultsList
}

// need import be structure

export interface ISupplierResource {
  code: string;
  name: string;
}

export interface IAircraftResource {
  code: string;
  name: string;
}

export interface IItineraryPointResource {
  time: Date;
  zoneOffsetSeconds: number;
  airport: string;
  terminal: string;
  airportName: string;
  cityCode: string;
  cityName: string;
}

export interface IBaggageAllowance {
  weight: number;
  numberOfPieces: number;
  unit: string;
}


export interface ISegmentGroupResource {
  number: number;
  segments: SegmentResource[];
  totalTime: string;
  groupHash: string;
}

export interface IHasId {
  id: string;
}

export type ProductSupplier = "AMADEUS" | "ALP_PACKAGE" | "SABRE";

export interface IMongoEntity extends IHasId {
  createdBy: string;
  updatedBy: string;
  createTs: Date;
  updateTs: Date;
}

export interface IHotelFacility {
  type: string;
  code: string;
  title: string;
  url: string;
  fileId: string;
}

export interface IHotelImage {
  type: string;
  title: string;
  url: string;
  fileId: string;
}

export interface IHotelRemark {
  type: string;
  text: string;
}

export interface IPoint {
  x: number;
  y: number;
}

export interface IGeoJson<T> {
  coordinates: T;
  type: string;
}

export interface IGeoJsonPoint extends IPoint, IGeoJson<number[]> {
  coordinates: number[];
}

export type AddressTypeOld = "LIVING" | "POST" | "REGISTRATION" | "GARAGE";

export interface IAddress {
  type: AddressTypeOld;
  name: string;
  city: string;
  country: string;
  street: string;
  house: string;
  apartment: string;
  zipCode: string;
  postBox: string;
}

export type MediaTypeOld = "MAIL" | "PHONE" | "MOBILE_PHONE" | "HOME_PHONE" | "FAX";
export type EntityCompletion = "BRIEF" | "FULL";

export interface IMedia {
  mediaType: MediaTypeOld;
  value: string;
  confirmed: Date;
}

export interface IHotel extends IMongoEntity {
  supplier: ProductSupplier;
  supplierId: string;
  name: string;
  description: string;
  stars: number;
  facilities: IHotelFacility[];
  images: IHotelImage[];
  remarks: IHotelRemark[];
  cityId: string;
  geoLocation: IGeoJsonPoint;
  address: IAddress;
  media: IMedia[];
  completion: EntityCompletion;
}

export type DealFeature = "FAST_OK" | "CAR_INCLUDED" | "CIV_MARRIAGE" | "SPA" | "SHOW" | "COUPON" | "FAMILY" | "CRUISE" | "EVENT" | "TOUR" | "TOUR_RUSSIAN" | "ONEWAY_RUSSIAN_CRUISE" | "YOUNG_VACATION" | "BOUTIQUE_TRAVEL" | "VACATION" | "SKI" | "SPORT" | "NATURE" | "EXTRIM" | "SPORT_PREMIUM" | "PREMIUM" | "KOSHER" | "LOWCOST" | "INCLUDING_BAGGAGE" | "INCLUDING_TRANSFER" | "CITY_PACKAGE" | "BEACH" | "NOT_INCLUDING_BAGGAGE";

export interface IStatPackageSearchItemResource {
  validatingCarrier: ISupplierResource;
  supplierAirline: ISupplierResource;
  codeShareCarrier: ISupplierResource;
  segmentGroups: ISegmentGroupResource[];
  provider: ISupplierResource;
  hotel: IHotel;
  fareBasis: string;
  roomType: string;
  dealFeatures: DealFeature[];
  dealInterval: IMinMax<string>;
  productId: string;
  supplier: ProductSupplier;
}

export interface IPackagesWithDigest extends IResponseWithDigest {
  packages: IPackage[]
}

export enum ResultPendingsNames {
  'getHotels',
  'getAndSetHotels',
  'getPackageProducts',
  'getAndSetFirstPackageProduct',
  'getProductsDetails',
  'getAndSetPackageProducts'
}

export enum OrderDealFeatures {
  INCLUDING_BAGGAGE = 'INCLUDING_BAGGAGE',
  NOT_INCLUDING_BAGGAGE = 'NOT_INCLUDING_BAGGAGE',
  INCLUDING_TRANSFER = 'INCLUDING_TRANSFER'
}

export type TResultPendings = { -readonly [key in keyof typeof ResultPendingsNames]: boolean}
export type TResultPendingsNames = keyof typeof ResultPendingsNames
