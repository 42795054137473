// tslint:disable-next-line:interface-name
interface BeforeInstallPromptEvent extends Event {
    platforms?: string[]
    userChoice?: Promise<{outcome: 'accepted'|'declined'}>
}
export function IsPwaHide() {
    return localStorage.getItem('pwaHide') == 'true' || false;
}

export function pwaHIde() {
    localStorage.setItem('pwaHide', 'true');
}

export function isPwaPopUp() {
    if (IsPwaHide()) {
        return false
    }
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export function startPwa() {

    let deferredPrompt;
    let isIstalled = false;
        window.addEventListener('beforeinstallprompt', function (e: BeforeInstallPromptEvent) {
            deferredPrompt = e;
        })
        window.addEventListener('appinstalled', function(e) {
            isIstalled = true;
        });

    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            navigator.serviceWorker.register('/service-worker.js').then(registration => {
                console.log('SW registered: ', registration);
            }).catch(registrationError => {
                console.log('SW registration failed: ', registrationError);
            });
        });
    }

    setTimeout(async () => {
        if (deferredPrompt !== null) {
            deferredPrompt.prompt();
            const {outcome} = await deferredPrompt.userChoice;
            if (outcome === 'accepted') {
                deferredPrompt = null;
            }
        }
    }, 3000)

}
