















import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class CustomCheckbox extends Vue {
  @Prop({ type: String, default: 'Checkbox' }) label!: string
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  @Prop({ type: Boolean, default: false }) readonly!: boolean
  @Prop({ type: Boolean, default: false }) value!: boolean
  @Prop({ type: String, default: null }) errorMessages!: string

  @Watch('value')
  initValue(value) {
    this.localValue = value
  }
  
  localValue: boolean = false

  created() {
    this.localValue = this.value
  }
}
