



















































import {Component, Prop} from "vue-property-decorator";
import Vue from 'vue'

@Component({})
export default class ModalMobile extends Vue {
  @Prop({ type: String, required: false }) title: string
  @Prop({default: false}) disableStyles: boolean
  @Prop({default: false}) dark: boolean
  @Prop({default: false}) withBackgroundImage: boolean
  @Prop({ default: true }) withCloseButton: boolean

  closeModal(): void {
    this.$emit('close')
  }
}
