
import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import PackageCompareMobileApp from 'applications/mobile/components/root-app/root-app.component.vue'

@Component({
  components: {
    PackageCompareMobileApp
  }
})
export default class Mobile extends Vue {
}
