import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

import {
  IPackageClass,
  IPackageHotelFacility,
  TAppMode,
  TPageableHotelsSort
} from 'applications/desktop/package-compare-app/types/package-compare-app.types'
import {Store} from "vuex";

export const PACKAGE_COMPARE_DESKTOP_NAME = 'PACKAGE_COMPARE_DESKTOP'

// DEPRECATED MODULE
export const PACKAGE_COMPARE_DESKTOP = (http: any, store: Store<any>) => {
  @Module({ name: PACKAGE_COMPARE_DESKTOP_NAME, namespaced: true, store: store })
  class PackageCompareDesktop extends VuexModule {
    private _appMode: TAppMode = 'NORMAL'
    private _defaultHotelsSort: TPageableHotelsSort[] = [{ PRICE: 'ASC' }]
    private _replacedPackage: IPackageClass = null
    private _indexOfSelectedComparisonColumn: number = null
    private _disabledFacilities: IPackageHotelFacility[] = []
    private _showHotelModal: boolean = false

    get appMode() {
      return this._appMode
    }
    get defaultHotelsSort() {
      return this._defaultHotelsSort
    }
    get replacedPackage() {
      return this._replacedPackage
    }
    get indexOfSelectedComparisonColumn() {
      return this._indexOfSelectedComparisonColumn
    }
    get disabledFacilities() {
      return this._disabledFacilities
    }
    get showHotelModal() {
      return this._showHotelModal
    }


    @Mutation
    setAppMode({ appMode }: { appMode: TAppMode }) {
      this._appMode = appMode
    }

    @Mutation
    setDisplayForModal({ show }: { show: boolean }) {
      this._showHotelModal = show
    }

    @Mutation
    setReplacedPackage(replacedPackage: IPackageClass) {
      this._replacedPackage = replacedPackage
    }

    @Mutation
    setIndexForSelectedComparisonColumn({ index }: { index: number }) {
      this._indexOfSelectedComparisonColumn = index
    }

    @Mutation
    disableFacility({ facility }: { facility: IPackageHotelFacility }) {
      this._disabledFacilities.push(facility)
    }

    @Mutation
    enableFacility({ facility }: { facility: IPackageHotelFacility }) {
      const index = this._disabledFacilities.findIndex(df => df.id === facility.id)

      if (index !== -1) {
        this._disabledFacilities.splice(index, 1)
      }
    }
  }

  return PackageCompareDesktop
}
