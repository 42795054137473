import type {
  TravelOrderResource
} from 'be-structures/typescript-generator/assembly'

import {
  ORDER_BLANK,
  IOrderResource
} from 'modules/product-order.v2/data/order/order.types'

import {
  Customer,
  Price
} from 'modules/common/common.types'

export const convertTravelOrderResourceToIOrderResource = (({ order }: { order: TravelOrderResource }): IOrderResource => {
  const convertedOrder = ORDER_BLANK

  if (order) {
    for (const item of Object.keys(ORDER_BLANK)) {
      convertedOrder[item] = order[item]
    }
    convertedOrder.contactPerson = new Customer(order.contactPerson)
    convertedOrder.paxPrice = new Price(order.paxPrice)
    convertedOrder.totalPrice = new Price(order.totalPrice)
  }
  
  return convertedOrder
})