


























































































































































































import Verte from 'verte';
import 'verte/dist/verte.css';
// register component globally
Vue.component('verte', Verte);
import Vue from 'vue'
import Component from 'vue-class-component'
import {
  Accessibility,
  colorsCustomFunctionsList, colorsFunctionsList, contentsFunctionsList, cursorFunctionsList, fontsFunctionsList
} from "./accessibility";
import {Prop} from "vue-property-decorator";
import Cert from "applications/desktop/accessibility/cert.vue";

@Component({
  name: "AccessPopup",
  components: {Cert}
})
export default class AccessPopup extends Vue {
  @Prop({type: Accessibility, required: true}) startAccessibility: Accessibility;
  isCertOpen: boolean = false;

  colorsCustomFunctionsList = colorsCustomFunctionsList;
  colorsFunctionsList = colorsFunctionsList;
  fontsFunctionsList = fontsFunctionsList;
  cursorFunctionsList = cursorFunctionsList;
  contentsFunctionsList = contentsFunctionsList;

  resetAll() {
    this.startAccessibility.resetAll();
  }

  changeColor(type: 'bg_color' | 'text_color', value: string) {
    this.startAccessibility.changeColor(type, value)
  }

  toggleColorsCustomFunctionsList(type: keyof typeof colorsCustomFunctionsList) {
    this.startAccessibility.toggleClass(type);
  }

  toggleCcursorFunctionsList(type: keyof typeof cursorFunctionsList) {
    this.startAccessibility.toggleClass(type);
  }

  toggleColorsFunctionsList(type: keyof typeof colorsFunctionsList) {
    this.startAccessibility.toggleClass(type);
  }

  toggleFontsFunctionsList(type: keyof typeof fontsFunctionsList) {
    if (type === "fontSize") {
      this.startAccessibility.toggleFontSize();
    } else
    this.startAccessibility.toggleClass(type);
  }

  toggleContentsFunctionsList(type: keyof typeof contentsFunctionsList) {
    if (type === 'focus_window') {
      this.startAccessibility.toggleWindow();
    }
    this.startAccessibility.toggleClass(type);
  }

  close() {
    this.startAccessibility.toggleAccessibility(false);
  }
};
