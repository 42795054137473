import dayjs from 'dayjs'
import isRTL from 'common/isRTL'
require('dayjs/locale/he')

export default (value?: string | Date, format: string = 'DD.MM.YYYY'): string => {
  if (value && !isRTL()) {
    return dayjs(value).format(format)
  } else if (value) {
    return dayjs(value).locale('he').format(format)
  }
}
