import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

import {
  SearchResultsViewPendingsNames,
  TSearchResultsViewPendings,
} from 'applications/common/components/search-results/types/search-results.types'
import {Store} from "vuex";

export const PRODUCT_RESULT_VIEW_NAME = 'PRODUCT_RESULT_MODULE_VIEW'

export const PRODUCT_RESULT_VIEW = (http: any, store: Store<any>) => {
  @Module({ name: PRODUCT_RESULT_VIEW_NAME, namespaced: true, store: store  })
  class ProductResultModuleView extends VuexModule {
    private _selectedCountryCode: string = null
    private _selectedCityCode: string = null
    private _quantityOfVisiblePackages: number = 5
    private _pendings: TSearchResultsViewPendings = {
      initializeFilter: false,
      applyFilter: false
    }


    get selectedCountryCode() {
      return this._selectedCountryCode
    }

    get selectedCityCode() {
      return this._selectedCityCode
    }

    get quantityOfVisiblePackages() {
      return this._quantityOfVisiblePackages
    }

    get pendings() {
      return this._pendings
    }


    @Mutation
    resetStore() {
      this._selectedCountryCode = null
      this._pendings = {
        initializeFilter: false,
        applyFilter: false
      }
    }

    @Mutation
    setPending({ pendingName, value }: { pendingName: keyof typeof SearchResultsViewPendingsNames, value: boolean }) {
      this._pendings[pendingName] = value
    }

    @Mutation
    setCountryCode(countryCode: string) {
      this._selectedCountryCode = countryCode
      this._selectedCityCode = null
    }

    @Mutation
    setCityCode(cityCode: string) {
      this._selectedCityCode = cityCode
    }

    @Mutation
    setQuantityOfVisiblePackages({ count }: { count: number }) {
      this._quantityOfVisiblePackages = count
    }
  }

  return ProductResultModuleView
}
