import { PRODUCT_SEARCH_NAME } from 'modules/product-search.v2/product-search.store'
import { PRODUCT_RESULT_NAME } from 'modules/product-result.v2/product-result.store'
import { PRODUCT_ORDER_NAME } from 'modules/product-order.v2/product-order.store' 
import { PRODUCT_RESULT_VIEW_NAME } from 'applications/common/components/search-results/vuex/store'

export default (state) => {
  return {
    [PRODUCT_SEARCH_NAME]: {
      _searchQuery: state[PRODUCT_SEARCH_NAME]?._searchQuery,
      _destinations: state[PRODUCT_SEARCH_NAME]?._destinations
    },
    [PRODUCT_RESULT_NAME]: {
      _filter: state[PRODUCT_RESULT_NAME]?._filter,
      _defaultFilter: state[PRODUCT_RESULT_NAME]?._defaultFilter,
      _comparablePackages: state[PRODUCT_RESULT_NAME]?._comparablePackages,
      // _favoritePackages: state[PRODUCT_RESULT_NAME]._favoritePackages,
      _selectedPackage: state[PRODUCT_RESULT_NAME]?._selectedPackage
    },
    [PRODUCT_RESULT_VIEW_NAME]: {
      _selectedCountryCode: state[PRODUCT_RESULT_VIEW_NAME]?._selectedCountryCode,
      _selectedCityCode: state[PRODUCT_RESULT_VIEW_NAME]?._selectedCityCode
    },
    [PRODUCT_ORDER_NAME]: {
      _agency: state[PRODUCT_ORDER_NAME]?._agency
    }
  }
}