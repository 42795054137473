import {
  CustomerResource,
  AsyncCallStatus,
  TravelOrderResource
} from 'be-structures/typescript-generator/assembly'

import {
  ICustomerResource
} from 'modules/product-order.v2/data/product-order.types'

export default (http: any) => {
  const getDefaultWebsite = () => {
    return http.get(`/customer/compare/statpackage/calendar/vacation/website`)
  }

  const beginOrder = (entityId: string): TravelOrderResource => {
    return http.post(`/customer/orders?agencyId=${entityId}`)
  }

  const addProductToOrder = (
    {
      orderId,
      productId,
      complectIds
    }: {
      orderId: string,
      productId: string,
      complectIds: string[]
    }
  ) => {
    return http.post(
      `/customer/orders/statpackages/${orderId}?itemId=${productId}&${encodeURIComponent(
        'complectIds[]'
      )}=${complectIds}`,
      {}
    )
  }

  const registrationNewCustomer = ({ orderId, customer }: { orderId: string, customer: ICustomerResource }): Promise<CustomerResource> => {
    return http.post(`/customer/orders/${orderId}/customers`, customer)
  }

  const setContactPerson = ({ orderId, customerId }: { orderId: string, customerId: string }) => {
    return http.put(`/customer/orders/${orderId}/contact_person?customerId=${customerId}`)
  }

  const addCustomerToOrderItem = ({
    orderId,
    itemId,
    customerId
  }: {
    orderId: string,
    itemId: string,
    customerId: string
  }) => {
    return http.post(`/customer/orders/${orderId}/${itemId}/passengers?customerId=${customerId}`)
  }

  const addBaggageToCustomer = ({
    orderId,
    itemId,
    customerId,
    additionalPaymentId
  }: {
    orderId: string,
    itemId: string,
    customerId: string
    additionalPaymentId: string
  }) => {
    return http.post(`/customer/orders/statpackages/${orderId}/items/${itemId}/customers/${customerId}/additions?additionalPaymentId=${additionalPaymentId}`)
  }

  const startOrderPreReservation = ({ orderId }: { orderId: string }): Promise<{ status: AsyncCallStatus }> => {
    return http.get(`customer/orders/${orderId}/pre_reservation_status`)
  }

  const startOrderReservation = ({ orderId }: { orderId: string }) => {
    return http.post(`customer/orders/${orderId}/reservations`, {})
  }

  const getReservationStatus = ({ orderId }: { orderId: string }): Promise<{ status: AsyncCallStatus }> => {
    return http.get(`customer/orders/${orderId}/reservation_status`, {})
  }

  const getCountriesList = () => {
    return http.get('places/countries', {})
  }
  const getPaxType = ({ productId, paxComplectId }: {productId: string, paxComplectId: string }) => {
    const headers= {
      'Accept': 'application/hal+json'
    }
    return http.get(
        `/customer/orders/statpackages/paxTypeByProduct/${productId}/${paxComplectId}`,
        {},
        headers
    )
  }


  return {
    beginOrder,
    getDefaultWebsite,
    registrationNewCustomer,
    setContactPerson,
    addCustomerToOrderItem,
    startOrderPreReservation,
    startOrderReservation,
    getReservationStatus,
    addProductToOrder,
    addBaggageToCustomer,
    getCountriesList,
    getPaxType
  }
}
