enum HotelsSort {
  'NAME' = 'NAME',
  'PRICE' = 'PRICE'
}

enum SortValues {
  'ASC' = 'ASC',
  'DESC' = 'DESC'
}

export {
  HotelsSort,
  SortValues
}