import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'

import { i18n } from 'modules/i18n/i18n.store'
import { PACKAGE_COMPARE_DESKTOP } from 'applications/desktop/package-compare-app/package-compare-app.store'
import { PRODUCT_SEARCH } from 'modules/product-search.v2/product-search.store'
import { PRODUCT_SEARCH_VIEW } from 'applications/common/components/prepare-search-data/vuex/store'
import { PRODUCT_RESULT } from 'modules/product-result.v2/product-result.store'
import { PRODUCT_RESULT_VIEW } from 'applications/common/components/search-results/vuex/store'
import { PRODUCT_ORDER } from 'modules/product-order.v2/product-order.store'
import { PRODUCT_ORDER_MODULE_VIEW } from 'applications/common/components/order/vuex/order.store'

export default (http, store: Store<any>) => {
  return {
    i18n: getModule(i18n(), store),
    PACKAGE_COMPARE_DESKTOP: getModule(PACKAGE_COMPARE_DESKTOP(http, store), store),
    PRODUCT_SEARCH_VIEW: getModule(PRODUCT_SEARCH_VIEW(), store),
    PRODUCT_SEARCH: getModule(PRODUCT_SEARCH(http, store), store),
    PRODUCT_RESULT: getModule(PRODUCT_RESULT(http, store), store),
    PRODUCT_RESULT_VIEW: getModule(PRODUCT_RESULT_VIEW(http, store), store),
    PRODUCT_ORDER: getModule(PRODUCT_ORDER(http, store), store),
    PRODUCT_ORDER_VIEW: getModule(PRODUCT_ORDER_MODULE_VIEW(http, store), store)
  }
}
