





































import  { Component, Mixins } from 'vue-property-decorator';

import RouterMixin from 'applications/common/mixins/router-mixin'
import {isPwaPopUp, pwaHIde} from "applications/mobile/pwa";
import Checkbox from "ui-modules/elements/checkbox/checkbox.vue";

@Component({
  components: {Checkbox}
})
export default class PwaPopUp extends Mixins(RouterMixin) {
  iosPopUp: boolean = false;
  dontShowAgainPwa: boolean = false;
  mounted() {
    this.iosPopUp = isPwaPopUp();
  }
  closePwa() {
    this.iosPopUp = false;
    if (this.dontShowAgainPwa) {
      pwaHIde();
    }
  }
};
