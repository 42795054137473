import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faWheelchair,
  faBaby,
  faCompass,
  faSpinner,
  faAngleLeft,
  faAngleRight,
  faAngleDown,
  faPlus,
  faCity,
  faPlane,
  faExchangeAlt,
  faTimes,
  faEllipsisH,
  faMobileAlt,
  faCaretDown,
  faMapMarkerAlt,
  faCheck,
  faRemoveFormat,
  faCalendar,
  faClock,
  faHotel,
  faStar,
  faMinusCircle,
  faPlusCircle,
  faTrashAlt,
  faSearch,
  faCaretLeft,
  faCaretRight,
  faThumbsUp,
  faMale,
  faFemale,
  faArrowRight,
  faArrowLeft,
  faMinus,
  faExclamationCircle,
  faChild,
  faPlaneDeparture,
  faPlaneArrival,
  faChevronLeft,
  faChevronRight,
  faInfoCircle,
  faSkiing,
  faPhone,
  faSun,
  faSlidersH,
  faHeart
} from '@fortawesome/free-solid-svg-icons'
import { faImages, faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import FontAwesomeIcon from '@fortawesome/vue-fontawesome'

import './style.scss'

library.add(
  faWheelchair,
  faAngleDown,
  faBaby,
  faCompass,
  faStar,
  faSpinner,
  faPlus,
  faCity,
  faPlane,
  faExchangeAlt,
  faAngleLeft,
  faAngleRight,
  faTimes,
  faEllipsisH,
  faMobileAlt,
  faCaretDown,
  faTimesCircle,
  faInfoCircle,
  faMapMarkerAlt,
  faCheck,
  faRemoveFormat,
  faCalendar,
  faClock,
  faHotel,
  faMinusCircle,
  faPlusCircle,
  faTrashAlt,
  faSearch,
  faCaretLeft,
  faCaretRight,
  faThumbsUp,
  faImages,
  faMale,
  faFemale,
  faArrowRight,
  faArrowLeft,
  faMinus,
  faExclamationCircle,
  faChild,
  faPlaneDeparture,
  faPlaneArrival,
  faChevronLeft,
  faChevronRight,
  faInfoCircle,
  faSkiing,
  faPhone,
  faSun,
  faSlidersH,
  faHeart
)

export default FontAwesomeIcon
